import { Link, useNavigate, useHistory, useLocation } from "react-router-dom";
import { deleteAll, getCookie } from "../utils/cookieHandling";
import React, { useContext, useEffect, useState } from "react";
import { UserRoleContext } from "../utils/userAuthorization";
import axios from "../utils/axiosInstance";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addAddressSchema } from "../utils/validations";
import Select from 'react-select';
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

import { Grid, Box, Button, TextField } from '@mui/material';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { toast } from "react-toastify";

export default function AddAddress() {
  const history = useNavigate();
  const location = useLocation()
  let addressState = location?.state
  const addresses = location || [];
  const roleContext = React.useContext(UserRoleContext);

  console.log(addresses)
  // const [addresses, setaddresses] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [addressLatLng, setAddressLatLng] = useState({
    latitude: '',
    longitude: ''
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addAddressSchema),
    defaultValues: { "address": '', "pincode": '', "city": '', "state": '' },
  });

  let existingAddresses = JSON.parse(localStorage.getItem('addresses')) || [];

  console.log('lattt===>', addressLatLng)

  const onSubmit = async (formData) => {
    const localData = getCookie("token");
    if (localData?.token) {
      if (addressState) {
        var bodyFormData = new FormData();
        bodyFormData.append('phone', localData?.phone);
        bodyFormData.append("id", localData?.id);
        bodyFormData.append("token", localData?.token);
        bodyFormData.append('address', formData?.address);
        bodyFormData.append('pincode', formData?.pincode);
        bodyFormData.append('city', formData?.city);
        bodyFormData.append('state', formData?.state);
        bodyFormData.append("type", selectedOption?.value);
        bodyFormData.append("address_id", addressState?.id);
        bodyFormData.append("lat", addressLatLng.latitude);
        bodyFormData.append("lng", addressLatLng.longitude);

        const { data, status } = await axios.post("editaddress", bodyFormData);
        // console.log(data)
        if (status === 200) {
          history('/address-list');
        }
      } else {
        if (selectedOption == null) {
          toast.warning("please select type")
          return
        }
        var bodyFormData = new FormData();
        bodyFormData.append('address', formData?.address);
        bodyFormData.append('pincode', formData?.pincode);
        bodyFormData.append('city', formData?.city);
        bodyFormData.append('state', formData?.state);
        bodyFormData.append("token", localData?.token);
        bodyFormData.append("id", localData?.id);
        bodyFormData.append("is_type", selectedOption?.value);
        const { data, status } = await axios.post("addaddress", bodyFormData);
        // console.log(data)
        if (status === 200) {
          history('/address-list');
          // alert(data?.message);
        }
      }
    } else {
      if (selectedOption == null) {
        toast.warning("please select type")
        return
      }

      let newAddressObj = {
        id: existingAddresses && existingAddresses?.length > 0 ? existingAddresses && existingAddresses?.length + 1 : 1,
        address: formData?.address,
        pincode: formData?.pincode,
        city: formData?.city,
        state: formData?.state,
        is_type: selectedOption?.value,
        lat: addressLatLng.latitude,
        lng: addressLatLng.longitude
      };

      existingAddresses.push(newAddressObj);

      localStorage.setItem('addresses', JSON.stringify(existingAddresses));
      history('/checkout');
    }

  };
  const options = [
    { value: 1, label: 'Home' },
    { value: 2, label: 'Office' },
    { value: 3, label: 'Other' },
  ];

  useEffect(() => {
    if (addressState?.state) {
      setValue("address", addressState?.address)
      setValue("pincode", addressState?.pincode)
      setValue("city", addressState?.city)
      setValue("state", addressState?.state)
      setSelectedOption(addressState?.type == 2 ? { value: 2, label: 'Office' } :
        addressState?.type == 1 ? { value: 1, label: 'Home' } : addressState?.type == 3 ? { value: 3, label: 'Other' } : { value: 1, label: 'Home' }
      )
    }
  }, [])

  return (
    <>
      <Header />

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Box display="flex" alignItems="center" mb={2}>
            <h2 className="app-back-title">Add Address</h2>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)} id="address-data">
            <Box className="app-login-form" sx={{ paddingLeft: 2, paddingRight: 2, marginTop: 3 }}>
              <Select
                placeholder="Select type..."
                className="react-select-container-new"
                defaultValue={selectedOption}
                value={selectedOption}
                onChange={(e) => { setSelectedOption(e) }}
                options={options}
              />
              {/* <TextField
                className="login-inp"
                type="text"
                placeholder="address"
                {...register("address")}
                fullWidth
                sx={{ marginTop: 3 }}
                error={!!errors?.address}
                helperText={errors?.address?.message}
              /> */}

              <Controller
                control={control}
                name="address"
                render={({ field: { onChange, value } }) => (
                  <div style={{ marginTop: 20 }}>
                    <GooglePlacesAutocomplete
                      {...register("address")}
                      selectProps={{
                        className: "react-select-container",
                        classNamePrefix: "inner",
                        onChange: (e) => {
                          console.log('locationnnnn', e)
                          geocodeByAddress(e?.value?.description)
                            .then(results => getLatLng(results[0]))
                            .then(({ lat, lng }) => {
                              setAddressLatLng({
                                latitude: lat,
                                longitude: lng
                              })
                              console.log('Successfully got latitude and longitude', { lat, lng })
                            });
                          setValue("address", e?.value?.description)
                        },
                      }}
                      apiKey="AIzaSyCZ0OAV7Q-p6TbHLGPZGJY26QSJtj0dt50"
                      autocompletionRequest={{
                        location: { lat: 17.3850, lng: 78.4867 },
                        radius: 1000,
                        componentRestrictions: { country: "in" },
                        strictBounds: true,
                      }}
                    />
                  </div>
                )}
              />
              <TextField
                className="login-inp"
                type="number"
                placeholder="pincode"
                {...register("pincode")}
                fullWidth
                sx={{ marginTop: 3 }}
                error={!!errors?.pincode}
                helperText={errors?.pincode?.message}
              />

              <TextField
                className="login-inp"
                type="text"
                placeholder="city"
                {...register("city")}
                fullWidth
                sx={{ marginTop: 3 }}
                error={!!errors?.city}
                helperText={errors?.city?.message}
              />

              <TextField
                className="login-inp"
                type="text"
                placeholder="state"
                {...register("state")}
                fullWidth
                sx={{ marginTop: 3 }}
                error={!!errors?.state}
                helperText={errors?.state?.message}
              />

              <Box display="flex" alignItems="center" marginTop={4} mb={4}>
                <Button
                  className="ion-activatable ripple-parent"
                  type="submit"
                  variant="contained"
                  fullWidth
                  form="address-data"
                >
                  {addressState?.id ? 'Save Changes' : 'Add'}
                </Button>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}
