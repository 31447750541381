
import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import "../styles/pages/home.scss";
import Footer from "../layouts/Footer";
import { Button, } from "@mui/material";
import design1 from '../how-we-work/design-1.svg';
import design2 from '../how-we-work/design-2.svg';
import design3 from '../how-we-work/design-3.svg';
import design4 from '../how-we-work/we-we-are-best.svg';
import CreateNewTab from "../layouts/CreateNewTab";
import stars from '../images/testimonial/stars.svg'
import danielle from '../images/testimonial/danielle.svg';
import link from '../images/testimonial/link.svg';
import HeaderService from "../layouts/HeaderService";
import axios from "../utils/axiosInstance";
import Axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "../utils/cookieHandling";
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Landing() {
    const gaEventTracker = useAnalyticsEventTracker('Waterproofing & Painting Services');
   
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [location, setLocation] = useState('');
    const [receiveUpdates, setReceiveUpdates] = useState(false);

    const [selectedArea, setSelectedArea] = useState('');
    const [serviceType, setServiceType] = useState('');

    const handleSelectArea = (event) => {
        setSelectedArea(event.target.value);
    };

    const handleServiceType = (event) => {
        setServiceType(event.target.value);
    };

    console.log(selectedArea, serviceType)

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (fullName == '' || phoneNumber == '' || selectedArea == '' || serviceType == '') {
            toast.warning("Please provide the full details")
        } else {
            const formData = new FormData();

            formData.append("phone", phoneNumber);
            formData.append("name", fullName);
            formData.append("address", selectedArea);
            formData.append("servicetype", serviceType);

            try {
                const res = await Axios.post("https://jigydi.com/admin/api/createorder", formData);
                console.log(res);
                if (res.status == 200) {
                    setFullName('');
                    setPhoneNumber('');
                    setSelectedArea('');
                    setServiceType('');
                    setReceiveUpdates(false);
                    handleClickOpen();
                }
            } catch (error) {
                // Handle errors if the axios request fails
                console.error(error);
                handleClickOpen2();
            }
        }
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen2 = () => {
      setOpen2(true);
    };
  
    const handleClose2 = () => {
      setOpen2(false);
    };
    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
            }
        }
    return (
        
        <><div>
       
        <Dialog
          open={open}
          className="form-submit-dialog"
          onClose={handleClose}
        >
          <DialogContent>
            <div class="form-submit-dialog-content">
                <img src="/images/checked-success.svg"/>
                <h3>Your request was successfully submitted. You'll get a callback shortly.</h3>
                <Button onClick={handleClose} variant="contained">Close</Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={open2}
          className="form-submit-dialog"
          onClose={handleClose2}
        >
          <DialogContent>
            <div class="form-submit-dialog-content">
                <img src="/images/checked-failed.svg"/>
                <h3>Oops!! Something went wrong. Please try again.</h3>
                <Button onClick={handleClose2} variant="contained">Close</Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
            <HeaderService />

            <div className="app-new-landing-banner" >
                <Container maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className="app-new-landing-banner-content">
                                <div className="landing-form-box">
                                    <div className="landing-form-box-content">
                                        <h2>Discover Your Colors, Inquire Today</h2>
                                        <p>Get the right assistance for all your painting needs</p>
                                        <div className="forms">
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label>Full Name</label>
                                                    <input type="text"
                                                        value={fullName}
                                                        onChange={(event) => setFullName(event.target.value)}
                                                        className="" id="fname" placeholder="Enter Name"></input>
                                                </div>
                                                <div className="form-group">
                                                    <label>Phone Number</label>
                                                    <input type="number"
                                                        value={phoneNumber}
                                                        onInput={maxLengthCheck}
                                                        onChange={(event) => setPhoneNumber(event.target.value)}
                                                        className="" id="phone" maxLength="10" placeholder="Enter Phone number"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Area Name</label>
                                                    {/* <input type="number"
                                                        value={location}
                                                        onChange={(event) => setLocation(event.target.value)}
                                                        className="" placeholder="Enter Pincode"></input> */}
                                                    <select value={selectedArea} onChange={handleSelectArea}>
                                                        <option disabled selected value="">Select area</option>
                                                        <option value="hyderabad">Hyderabad</option>
                                                        <option value="secundrabad">Secundrabad</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Service Type</label>
                                                    <select value={serviceType} onChange={handleServiceType}>
                                                        <option disabled selected value="">Select Service</option>
                                                        <option value="waterproof">Water Proofing</option>
                                                        <option value="paint">Painting</option>
                                                    </select>
                                                </div>
                                                <div className="check-box">
                                                    <input type="checkbox"
                                                        checked={receiveUpdates}
                                                        onChange={(event) => setReceiveUpdates(event.target.value)}
                                                        class="form-check-input" id="exampleCheck1" />
                                                    <label class="form-check-label" for="exampleCheck1">Yes, I would like to receive important updates and notification WhatsApp.</label>
                                                </div>
                                                <div className="enquire-button">
                                                    <button type="submit">Enquire now for Free Inspection</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className="discover-colors">
                                <h2>Discover Your Colors</h2>
                            </div>
                            <div className="discover-main-buttons">
                                <div className="discover-buttons">
                                    <a className="vew-our-services" href="#keyservices">View Our Service</a>
                                    <a className="call-us" href="tel:040-46462222">Call Us</a>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="how-we-work">
                <Container maxWidth="lg">
                    <div className="how-we-work-content">
                        <h2>How We Work</h2>
                        {/* <p>Lorem Epson is a dummy text to fill the sentences Lorem Epson is a dummy text to fill the sentences.</p> */}
                    </div>
                    <div className="how-we-work-boxs">
                        <Grid container justifyContent={'center'} spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className="design-box design-box-1">
                                    <img src={design1} />
                                    <div className="design-box-content">
                                        <h5>24/7 Design Assistance</h5>
                                        <p>24/7 Customer Support for Painting and Interior Design Scheduling Assistance.</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className="design-box design-box-2">
                                    <img src={design2} />
                                    <div className="design-box-content">
                                        <h5>Design Advisor</h5>
                                        <p>Expert in color quotes and interior design for personalized spaces</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className="design-box design-box-3">
                                    <img src={design3} />
                                    <div className="design-box-content">
                                        <h5>Post-Paint & Interior Cleanup Specialist</h5>
                                        <p>Effortless cleanup post-paint and interior work, a spotless, refreshed space</p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='app-new-form-box'>
                        <div className='main-form-box'>
                            <Container>
                                <form onSubmit={handleSubmit}>
                                    <div className='form-box'>
                                        <div className='form-box-content'>
                                            <div className='box-1'>
                                                <label className=''>Name</label>
                                                <input type='text'
                                                    value={fullName}
                                                    onChange={(event) => setFullName(event.target.value)}
                                                    className='' id='fname' placeholder='Enter Name' ></input>
                                            </div>
                                            <div className='box-1'>
                                                <label className=''>Mobile No</label>
                                                <input type='number'
                                                    value={phoneNumber}
                                                    onInput={maxLengthCheck}
                                                    maxLength="10"
                                                    onChange={(event) => setPhoneNumber(event.target.value)}
                                                    className='' id='phone' placeholder='Enter Mobile No' ></input>
                                            </div>
                                            <div className="box-1">
                                                <label>Area Name</label>
                                                {/* <input type="number"
                                                        value={location}
                                                        onChange={(event) => setLocation(event.target.value)}
                                                        className="" placeholder="Enter Pincode"></input> */}
                                                <select value={selectedArea} onChange={handleSelectArea}>
                                                    <option disabled selected value="">Select area</option>
                                                    <option value="hyderabad">Hyderabad</option>
                                                    <option value="secundrabad">Secundrabad</option>
                                                </select>
                                            </div>
                                            <div className="box-1">
                                                <label>Service Type</label>
                                                <select value={serviceType} onChange={handleServiceType}>
                                                    <option disabled selected value="">Select Service</option>
                                                    <option value="waterproof">Waterproofing</option>
                                                    <option value="paint">Painting</option>
                                                </select>
                                            </div>
                                            <div className='box-1'>
                                                <div className="discover-buttons">
                                                    <button className="explore-cta" type="submit">Consult Now For free Inspection</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Container>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="why-we-are-best">
                <Container maxWidth="lg">
                    <div className="why-we-are-best-content">
                        <h2>Why we are the Best</h2>
                        {/* <p>Lorem Epson is a dummy text to fill the sentences Lorem Epson is a dummy text to fill the sentences.</p> */}
                    </div>
                    <div className="why-we-are-best-boxs">
                        <Grid container alignItems={'center'} spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className="why-we-are-best-content-2">
                                    <div className="why-we-are-best-mini-content">
                                        <h3>3000+ </h3>
                                        <p>Painting and Waterproofing Projects</p>
                                    </div>
                                    <div className="why-we-are-best-mini-content">
                                        <h3>Supervisor monitoring</h3>
                                        <p>Best Quality </p>
                                    </div>
                                </div>
                                <div className="why-we-are-best-content-2">
                                    <div className="why-we-are-best-mini-content">
                                        <h3>Within 24 hrs</h3>
                                        <p>Response Time</p>
                                    </div>
                                    <div className="why-we-are-best-mini-content">
                                        <h3>Waterproofing 5 years (material and service) and Painting 1 year (service)</h3>
                                        <p>Warranty</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <div className="why-we-are-best-image">
                                    <img src={design4} />
                                </div>
                            </Grid>

                        </Grid>
                    </div>
                </Container>
            </div>
            <div className="what-we-offer">
                <div id="keyservices"></div>
                <Container maxWidth="lg">
                    <div className="what-we-offer-content">
                        <h2>What we Offer</h2>
                        {/* <p>Lorem Epson is a dummy text to fill the sentences Lorem Epson is a dummy text to fill the sentences.</p> */}
                    </div>
                    <div className="what-we-offer-tabs">
                        <CreateNewTab />
                    </div>
                    <div className='app-new-form-box'>
                        <div className='main-form-box'>
                            <Container>
                                <form onSubmit={handleSubmit}>
                                    <div className='form-box'>
                                        <div className='form-box-content'>
                                            <div className='box-1'>
                                                <label className=''>Name</label>
                                                <input type='text'
                                                    value={fullName}
                                                    onChange={(event) => setFullName(event.target.value)}
                                                    className='' id='fname' placeholder='Enter Name' ></input>
                                            </div>
                                            <div className='box-1'>
                                                <label className=''>Mobile No</label>
                                                <input type='number'
                                                    value={phoneNumber}
                                                    onInput={maxLengthCheck}
                                                    maxLength="10"
                                                    onChange={(event) => setPhoneNumber(event.target.value)}
                                                    className='' id='phone' placeholder='Enter Mobile No' ></input>
                                            </div>
                                            <div className="box-1">
                                                <label>Area Name</label>
                                                {/* <input type="number"
                                                        value={location}
                                                        onChange={(event) => setLocation(event.target.value)}
                                                        className="" placeholder="Enter Pincode"></input> */}
                                                <select value={selectedArea} onChange={handleSelectArea}>
                                                    <option value="">Select an option</option>
                                                    <option value="hyderabad">Hyderabad</option>
                                                    <option value="secundrabad">Secundrabad</option>
                                                </select>
                                            </div>
                                            <div className="box-1">
                                                <label>Service Type</label>
                                                <select value={serviceType} onChange={handleServiceType}>
                                                    <option value="">Select Service</option>
                                                    <option value="waterproof">Waterproofing</option>
                                                    <option value="paint">Painting</option>
                                                </select>
                                            </div>
                                            <div className='box-1'>
                                                <div className="discover-buttons">
                                                    <button className="explore-cta" type="submit">Consult Now For free Inspection</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Container>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="testimonial">
                <Container maxWidth="lg">
                    <div className="testimonial-content">
                        <h2>Testimonial</h2>
                        {/* <p>Lorem Epson is a dummy text to fill the sentences Lorem Epson is a dummy text to fill the sentences.</p> */}
                    </div>
                    <div className="testimonial-boxs">
                        <Grid container justifyContent={'center'} spacing={2}>
                            <Grid item xs={12} sm={12} md={5}>
                                <div className="testimonial-box">
                                    <img className="stars" src={stars} />
                                    <h3>Venkata Ramarao Movva</h3>
                                    <p>Got waterproofing and Epoxy done from Jigydi for our independent house in Dammaiguda. Highly satisfied with the outcome, quality and the entire experience of dealing with the staff. Best part is they get all materials on their own. additionally they offer few years warranty also.</p>
                                    <p>They have completed the work professionally as per the agreed terms. Very much impressed with their workmanship.</p>
                                    <p>Our Supervisor was Gopal.B, who handled everything very well. A through professional, He used to inspect the work every day and provide frequent updates.</p>

                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <div className="testimonial-box">
                                    <img className="stars" src={stars} />
                                    <h3>Venu Madhav</h3>
                                    <p>Got waterproofing and house painting (exterior and interior) done from Jigydi for our independent house in tirumulgherry. Highly satisfied with the outcome, quality and the entire experience of dealing with the staff. Best part is they get all materials on their own. additionally they offer few years warranty also.</p>
                                    <p>They have completed the work professionally as per the agreed terms. Even after few days, they came back to rectify an very minor issue with waterproofing. Very much impressed with their workmanship.</p>
                                    <p>Our Supervisor was DAVID, who handled everything very well. A through professional, He used to inspect the work every day and provide frequent updates. I asked for him specifically to be assigned during the second round of works.</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <div className="testimonial-box">
                                    <img className="stars" src={stars} />
                                    <h3>Narayana Rao Penumudi</h3>
                                    <p> Painting of 2BHK at Mallapur was done by Jigydi. The supervisor Mr.Rajesh Goud and his team have done a very good job. The quality of work is very good. I am very happy with it. I did not go to site at all to monitor work. Thanks and keep up the good work.</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <div className="testimonial-box">
                                    <img className="stars" src={stars} />
                                    <h3>Navya Aruna</h3>
                                    <p>I'm truly impressed by the painting work done! The team displayed exceptional skill and professionalism throughout the process. The attention to detail is evident. I'm delighted with the final result!</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <div className="testimonial-box">
                                    <img className="stars" src={stars} />
                                    <h3>Syamala Juvvala</h3>
                                    <p>  Iam satisfied with jigydi team work about waterproofing done at my home .very good persons they are talking well and a very good Experience. The supervisor Gopal krishna have a good communication and well explained regarding the water seepage issues and worker Jaladher done a good Job.</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <div className="testimonial-box">
                                    <img className="stars" src={stars} />
                                    <h3>Pramod B</h3>
                                    <p>   I am satisfied with the waterproofing work performed by the team led by David. Approach towards customer and communication is professional. As it carries a rider of 5 years warranty hoping that there will not be any leakages if so Jigydi Home care services company will adhere to the commitment and keep up their good work and service</p>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='app-new-form-box min-app-new-form-box'>
                        <div className='main-form-box mini-form-box'>
                            <Container>
                                <div className='form-box app-new-form-box-2'>
                                    <div className='form-box-content discover-box-buttons'>
                                        <div className='box-1'>
                                            <div className="discover-buttons">
                                                <a className="call-us" href="#">GET ME QUOTE</a>
                                            </div>
                                        </div>
                                        <div className='box-1'>
                                            <div className="discover-buttons">
                                                <a className="call-us" href="#">REQUEST A CALL BACK</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>
                </Container>
            </div>

            <Footer />
        </>
    );
}

export default Landing;

