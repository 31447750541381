import * as React from "react";
import "../styles/pages/login.scss";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../utils/axiosInstance";
import { registerSchema } from "../utils/validations/index";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Register() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerSchema),
  });

  const history = useNavigate();

  const onSubmit = async (formData) => {
    // console.log("callllle");
    var bodyFormData = new FormData();
    console.log(bodyFormData)
    bodyFormData.append("email", formData?.email);
    bodyFormData.append("firstName", formData?.firstName);
    bodyFormData.append("lastName", formData?.lastName);
    bodyFormData.append("phone", formData?.phone);
    bodyFormData.append("type", 'register');
    const { data, status } = await axios.post("registeruser", bodyFormData);
    console.log("data ", data)
    if (data?.success == true) {
      if (status === 200) {
        history("/otp-auth", { state: { mobile: formData?.phone } });
      }
    } else if (data?.success == false) {
      toast.error(data?.message)
    }
  };

  return (
    <>
      <div className="app-login">
        <Container className="h-100" maxWidth="lg">
          <Grid className="h-100" container spacing={10}>
            <Grid className="h-100 hide-on-mobile" item md="6" lg="6" xl="6">
              <div className="app-login-image">
                {/* <img
                  className="app-login-page-logo"
                  src="/images/jigydi-logo.svg"
                /> */}
                <img
                  className="app-login-image-figure"
                  src="/images/splash-screen-web.svg"
                />
              </div>
            </Grid>
            <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
              <div className="app-login-header">
                <img
                  className="app-login-page-logo"
                  src="/images/jigydi-logo.svg"
                />
              </div>
              <div className="app-login-content">
                <div className="app-login-content-wrapper">
                  <div className="app-login-content-heading">
                    <h5>Welcome !</h5>
                    <p>Register here</p>
                  </div>
                  <form
                    className="app-login-content-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="app-login-form-field">
                      <label>Mobile Number</label>
                      <input
                        type="phone"
                        placeholder="Enter your mobile Number"
                        maxLength="10"
                        {...register("phone")}
                      />
                      <span className="app-register-form-field-error">
                        {errors?.phone?.message}
                      </span>
                    </div>

                    <div className="app-login-form-field">
                      <label>First name</label>
                      <input
                        type="text"
                        placeholder="Enter your first name"
                        {...register("firstName")}
                      />
                      <span className="app-register-form-field-error">
                        {errors?.firstName?.message}
                      </span>
                    </div>
                    <div className="app-login-form-field">
                      <label>Last name</label>
                      <input
                        type="text"
                        placeholder="Enter your last name"
                        {...register("lastName")}
                      />
                      <span className="app-register-form-field-error">
                        {errors?.lastName?.message}
                      </span>
                    </div>
                    <div className="app-login-form-field">
                      <label>Email</label>
                      <input
                        type="email"
                        placeholder="Enter your email"
                        {...register("email")}
                      />
                      <span className="app-register-form-field-error">
                        {errors?.email?.message}
                      </span>
                    </div>
                    <Button
                      className="verify-button"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Register
                    </Button>
                  </form>
                  <p className="app-login-description">
                    Already a customer
                    <Link to="/login">
                      <Button color="primary" variant="text">
                        Login to your account
                      </Button>
                    </Link>
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
export default Register;
