import React, { useState, createContext } from "react";
import { getCookie } from "./cookieHandling";
import axios from "./axiosInstance";
import { getCurrentLocation } from "./functions";
const UserRoleContext = createContext(null);
const UserRoleContextConsumer = UserRoleContext.Consumer;

const UserRoleContextProvider = (props) => {
  const [userDetails, setUserDetails] = useState({
    isLoggedIn: false,
  });
  const [cartList, setCartList] = useState([]);
  const [location, setLocation] = useState(null);
  const [cartTotal, setCartTotal] = useState(0);
  const [localCart, setLocalCart] = useState([]);
  const [userdata, setUserdata] = useState({})


  // Update the details context
  const updateContext = (data) => {
    setUserDetails((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const getCartDetails = async () => {
    var bodyFormData = new FormData();
    const localData = getCookie("token");
    if (localData == null) return;
    bodyFormData.append("phone", localData?.phone);
    bodyFormData.append("token", localData?.token);
    bodyFormData.append("id", localData?.id);
    // bodyFormData.append("type", selectedCategory);
    const { data } = await axios.post("getcartdetails", bodyFormData);
    setCartList(data?.data);
    let total = 0;
    data?.data.map((item) => {
      total = total + parseFloat(item?.rate)
      // total = total + parseFloat(item?.rate) * parseInt(item?.quantity);
      // console.log("totalllll", total);
    });
    setCartTotal(total);
  };
  // console.log("cartttttttttt", cartTotal);

  const GetProfileDetails = async () => {
    const localData = getCookie("token");
    var bodyFormData = new FormData();
    bodyFormData.append("phone", localData?.phone);
    bodyFormData.append("token", localData?.token);
    bodyFormData.append("id", localData?.id);
    const { data } = await axios.post(
      "GetProfileDetails",
      bodyFormData
    );
    if (data?.code == 200) {
      setUserdata(data?.data)
    } else {
      setUserdata({})
    }
  };


  return (
    <UserRoleContext.Provider
      value={{
        ...userDetails,
        updateContext: updateContext,
        getCartDetails,
        cartList,
        cartTotal,
        setLocation,
        location,
        localCart, setLocalCart, userdata, GetProfileDetails, setUserdata
      }}
    >
      {props.children}
    </UserRoleContext.Provider>
  );
};

export { UserRoleContext, UserRoleContextProvider, UserRoleContextConsumer };
