import * as React from "react";
import "../styles/pages/login.scss";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../utils/axiosInstance";
import { loginSchema } from "../utils/validations";
import { toast } from "react-toastify";

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });
  const history = useNavigate();
  const onSubmit = async (formData) => {
    var bodyFormData = new FormData();
    bodyFormData.append("phone", formData?.mobile);
    bodyFormData.append("type", 'login');
    const { data, status } = await axios.post("registeruser", bodyFormData);
    if (data?.success == true) {
      if (status === 200) {
        localStorage.setItem("mobile", formData?.mobile);
        history("/otp-auth", { state: { mobile: formData?.mobile } });
      }
    } else if (data?.success == false) {
      toast.error(data?.message)
    }
  };
  return (
    <>
      <div className="app-login">
        <Container className="h-100" maxWidth="lg">
          <Grid className="h-100" container spacing={10}>
            <Grid className="h-100 hide-on-mobile" item md="6" lg="6" xl="6">
              <div className="app-login-image">
                {/* <img
                  className="app-login-page-logo"
                  src="/images/jigydi-logo.svg"
                /> */}
                <img
                  className="app-login-image-figure"
                  src="/images/splash-screen-web.svg"
                />
              </div>
            </Grid>
            <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
              <div className="app-login-header">
                {/* <img
                  className="app-login-page-logo"
                  src="/images/jigydi-logo.svg"
                /> */}
                <Link to="/home">
                  <img src="/images/jigydi-logo.png" alt="jigydi logo png" style={{ width: '8%' }} />
                  <img src="/images/jigydi-logo.svg" alt="Jigydi Logo" />
                </Link>
              </div>
              <div className="app-login-content">
                <div className="app-login-content-wrapper">
                  <div className="app-login-content-heading">
                    <h5>Welcome Back!</h5>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing</p> */}
                  </div>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="app-login-content-form"
                  >
                    <div>
                      <label>Login in to your account</label>
                      <input
                        type="number"
                        placeholder="Mobile Number"
                        maxLength="10"
                        {...register("mobile")}
                      />
                      <span className="app-register-form-field-error">
                        {errors?.mobile?.message}
                      </span>
                    </div>
                    <Button
                      className="verify-button"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Login
                    </Button>
                  </form>
                  <p className="app-login-description">
                    Not registered yet?
                    <Link to="/register">
                      <Button color="primary" variant="text">
                        Create an Account
                      </Button>
                    </Link>
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
export default Login;
