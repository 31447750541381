import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../styles/pages/history.scss';
import { getCookie } from '../utils/cookieHandling';
import axios from "../utils/axiosInstance";
import { useContext, useEffect, useState } from 'react';
import { UserRoleContext } from '../utils/userAuthorization';
import moment from 'moment';
import { getOrderStatus } from '../utils/functions';
// import { timer } from 'ionicons/icons';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import '../styles/pages/detail-history.scss'
import { Grid, Box, Button, Typography, Container } from '@mui/material';
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { toast } from 'react-toastify';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 700,
  background: "#FFFFFF",
  border: "1px solid #EDEDED",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
  borderRadius: "20px",
};

const HistoryDetail = () => {

  const history = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null)
  const location = useLocation();
  const [order_total, setOrder_total] = useState(0)
  const { state } = location;
  const roleContext = useContext(UserRoleContext);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [startDate, setStartDate] = useState(null);
  const [allFiveDays, setAllFiveDays] = useState([]);
  const [times, setTimes] = useState(null);


  const getAllCategories = async () => {

    var bodyFormData = new FormData();
    const localData = getCookie("token");
    bodyFormData.append("phone", localData?.phone);
    bodyFormData.append("token", localData?.token);
    bodyFormData.append("id", localData?.id);
    bodyFormData.append("order_id", state?.order_id);
    // bodyFormData.append("type", selectedCategory);
    const { data, status } = await axios.post(
      "Getcarthistory",
      bodyFormData
    );
    if (status === 200) {
      let total = 0;
      data?.data.map((item) => {
        total = total + parseFloat(item?.rate) * parseInt(item?.quantity);
      });
      setOrder_total(total);
      setOrderDetails(data?.data)
      // setCategories(data?.data);
    }
  };
  useEffect(() => {
    getAllCategories();
  }, [])

  const cancelOrder = async () => {
    var bodyFormData = new FormData();
    const localData = getCookie("token");
    bodyFormData.append("phone", localData?.phone);
    bodyFormData.append("token", localData?.token);
    bodyFormData.append("id", localData?.id);
    bodyFormData.append("order_id", state?.order_id);
    let res = await axios.post('cancelorder', bodyFormData)
    if (res.status == 200) {
      setShowModal(false);
      toast.success("Order cancelled successsfully");
      history(-1)
    }
  }
  const days = ["M", "T", "W", "T", "F", "S", "S"];
  const timeList = [
    "9:00 AM - 11:00 AM",
    "11:00 AM - 13:00 PM",
    "13:00 PM - 15:00 PM",
    "15:00 PM - 17:00 PM",
    "17:00 PM - 19:00 PM",
  ];

  const getNextFiveDays = () => {
    let fiveDays = [];
    for (var i = 0; i < 5; i++) {
      const today = new Date();
      // to return the date number(1-31) for the specified date
      // console.log("today => ", today);
      let tomorrow = new Date();
      tomorrow.setDate(today.getDate() + i + 1);
      //returns the tomorrow date
      fiveDays.push(tomorrow);
    }
    setAllFiveDays([...fiveDays]);
    // console.log(fiveDays);
  };

  useEffect(() => {
    getNextFiveDays();
  }, []);


  const handlePayment = async () => {
    if (times == null || startDate == null) {

      alert("please select date and time")
      return
    }


    var bodyFormData = new FormData();
    const localData = getCookie("token");
    bodyFormData.append("phone", localData?.phone);
    bodyFormData.append("token", localData?.token);
    bodyFormData.append("id", localData?.id);
    bodyFormData.append("preferable_date", moment(new Date(startDate)).format("DD-MM-YYYY"));
    bodyFormData.append("preferable_time", times);
    bodyFormData.append("order_id", state?.order_id);
    let res = await axios.post('rescheduledDate', bodyFormData)
    if (res.status == 200) {
      toast.success("Order rescheduled successsfully");
      handleClose1()
      history("/history");
    }
  };

  return (
    <>
      <Header />
      <Container maxWidth="lg">

        <Box >
          <div className="app-back-wrapper">

            <h2 className="app-back-title">Order details</h2>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box className="app-history-detail-noti-card">
                <Box className="app-history-detail-noti-card-cnt">
                  <Typography variant="h2">Your request was {getOrderStatus(state?.order_status)}</Typography>
                  <Typography>
                    <img src="/images/duration.svg" alt="" />
                    <span>{moment(state?.preferable_date).format("DD MMM YYYY")} {state?.preferable_time}</span>
                  </Typography>
                  {orderDetails?.map((item) => (
                    <div className='checkout-order-summary mt-10' key={item?.id}>
                      <div className='checkout-order-summary-info'>
                        <div className='checkout-page-address-home'>
                          <span>{item?.service_name}</span>
                        </div>
                        <p>x{item?.quantity}</p>
                      </div>
                      <div class="checkout-order-summary-price">
                        <span class="checkout-order-summary-price-info">
                          <img src="/images/rupee-symbol.svg" alt="" /> {item?.rate}
                        </span>
                      </div>

                    </div>
                  ))}

                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: 4 }}>
                    <Button
                      variant="contained"
                      onClick={handleOpen1}
                    >
                      Reschedule
                    </Button>
                    {getOrderStatus(state?.order_status) == 'Not Required' ? null : <Button
                      variant="contained"
                      color="error"
                      onClick={() => { setShowModal(true) }}
                    >
                      Cancel
                    </Button>
                    }
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="app-history-detail-booking-card">
                <Typography variant="h6" className="app-history-detail-booking-card-title">Booking details</Typography>
                <ul className="app-history-detail-booking-list">
                  <li className="app-history-detail-booking-list-item">
                    <img className="app-history-detail-booking-list-item-home" src="/images/home.svg" alt="" />
                    <div className="app-history-detail-booking-list-info">
                      <Typography>{state?.cus_address}</Typography>
                    </div>
                  </li>
                  <li className="app-history-detail-booking-list-item">
                    <img src="/images/duration.svg" alt="" />
                    <div className="app-history-detail-booking-list-info">
                      <Typography>Order date: {state?.order_date}</Typography>
                    </div>
                  </li>
                  <li className="app-history-detail-booking-list-item">
                    <img src="/images/list.svg" alt="" />
                    <div className="app-history-detail-booking-list-info">
                      <Typography>Total Amount: ₹{order_total}</Typography>
                    </div>
                  </li>
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        onClose={handleClose1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open1}>
          <Box sx={style}>
            <div className="app-slot-modal-wrapper">
              <div className="app-slot-modal-header">
                <h1>ReSchedule</h1>
                <Button
                  onClick={handleClose1}
                  className="modal-close-button"
                >
                  <img src="/images/close.svg" />
                </Button>
              </div>
              <div className="app-slot-modal-body">
                <div className="app-slot-modal-body-description">
                  <h2>Pick Date & Time</h2>
                  <p>
                    When would you like us to come to your address
                    ?
                  </p>
                </div>
                <div className="app-slot-modal-body-calender">
                  <h4>
                    {startDate
                      ? moment(new Date(startDate)).format(
                        "DD MMMM YYYY"
                      )
                      : "Select Date"}
                  </h4>
                  <ul className="app-slot-calender-dates">
                    {allFiveDays.map((item) => {
                      return (
                        <li>
                          <Button
                            style={{ display: "inline" }}
                            // className="calender-dates-button calender-dates-button-selected"
                            className={`calender-dates-button ${startDate == item
                              ? "calender-dates-button-selected"
                              : ""
                              }`}
                            onClick={() => setStartDate(item)}
                          >
                            <span style={{ display: "block" }}>
                              {
                                days[
                                new Date(item).getDay() - 1 ==
                                  -1
                                  ? 6
                                  : new Date(item).getDay() - 1
                                ]
                              }
                            </span>
                            <span>
                              {new Date(item).getDate()}
                            </span>
                          </Button>
                        </li>
                      );
                    })}
                  </ul>
                  <ul className="app-slot-calender-time-slot">
                    {timeList.map((item, index) => {
                      return (
                        <li>
                          <Button
                            // className="slot-time-button slot-time-button-selected"
                            className={`slot-time-button ${times == index
                              ? "slot-time-button-selected"
                              : ""
                              }`}
                            onClick={() => setTimes(index)}
                          >
                            <span>{item}</span>
                          </Button>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="slot-order-button">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePayment}
                  >
                    Reschedule
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showModal}
        onClose={() => { setShowModal(false) }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <Box sx={style}>
            <div className="app-slot-modal-wrapper">
              <div className="app-slot-modal-header">
                <h1>Cancel order</h1>
                <Button
                  onClick={() => { setShowModal(false) }}
                  className="modal-close-button"
                >
                  <img src="/images/close.svg" />
                </Button>
              </div>
              <div
                className="app-slot-modal-body"
                style={{ padding: "0 15px" }}
              >
                <p>Are you sure to cancel order?</p>
                <div className="slot-order-button">

                  <Button
                    style={{ margin: "0 10px" }}
                    variant="contained"
                    color="error"
                    onClick={cancelOrder}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>


      <Footer />
    </>
  );
};

export default HistoryDetail;
