import { Link, useNavigate } from 'react-router-dom';
import { getCookie } from '../utils/cookieHandling';
import React, { useContext, useEffect, useState } from "react";
import { UserRoleContext } from "../utils/userAuthorization";
import axios from "../utils/axiosInstance";
import { getOrderStatus } from '../utils/functions';
import moment from 'moment';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import '../styles/pages/history.scss'
import { Container } from '@mui/material';

const OrderHistory = () => {


  const history = useNavigate();
  const [categories, setCategories] = useState(null);
  const roleContext = useContext(UserRoleContext);


  const getAllCategories = async () => {
    var bodyFormData = new FormData();
    const localData = getCookie("token");
    bodyFormData.append("phone", localData?.phone);
    bodyFormData.append("token", localData?.token);
    bodyFormData.append("id", localData?.id);
    // bodyFormData.append("type", selectedCategory);
    const { data, status } = await axios.post(
      "Getorderhistory",
      bodyFormData
    );
    if (status === 200) {
    //   roleContext.setorders(data?.data)
      setCategories(data?.data);
    }
  };

  const handleOrders = (item) => {
    history('/history-detail', { state: item });
  };

  useEffect(() => {
    roleContext.getCartDetails();
    getAllCategories();
  }, [])

  const timeList = [
    "9:00 AM - 11:00 AM",
    "11:00 AM - 13:00 PM",
    "13:00 PM - 15:00 PM",
    "15:00 PM - 17:00 PM",
    "17:00 PM - 19:00 PM",
  ];


  return (
    <>
    <Header/>
        <div className="app-history-wrapper">
    <Container maxWidth="lg">
          {
           categories?.map((item) => {
            return (
              <div className="app-history-card ion-activatable ripple-parent" onClick={() => handleOrders(item)} style={{cursor:'pointer'}}>
                <span className='history-status-badge history-status-completed-badge'>{getOrderStatus(item?.order_status)}</span>
                <div className="app-history-card-profile">
                  <img className="app-history-card-profile-img" src="/images/profile.jpg" />
                  <div className="app-history-card-profile-cnt">
                    <h3>{item?.worker_id ? (item?.worker_name || item?.worker_id) : "worker not assigned"}</h3>
                    <p><img src="/images/rating-star.svg" /> <span>{item?.rating}</span></p>
                  </div>
                </div>
                <div className="app-history-card-info">
                  <div className="app-history-card-info-cnt">
                    <h2>Order Id : {item?.order_number}</h2>
                    <p><img src="/images/duration.svg" /> <span>{moment(item?.preferable_date).format("DD MMM YYYY")} {timeList[parseInt(item?.preferable_time)]}</span></p>
                  </div>
                  <h1 className="app-history-card-info-price"><img src="assets/images/rupee-symbol.svg" /> <span>{item?.total_payment}</span></h1>
                </div>
              </div>
            );
          })
          
          }

      </Container>
        </div>
      <Footer/>
</>
  );
};

export default OrderHistory;
