import * as React from "react";
import useAnalyticsEventTracker from '../useAnalyticsEventTracker';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Header from "../layouts/Header";
import ServicesMain from "../components/services/ServicesMain";
import "../styles/pages/home.scss";
import OffersSlider from "../components/OffersSlider";
import SpecialServicesSlider from "../components/SpecialServicesSlider";
import Footer from "../layouts/Footer";
import axios from "../utils/axiosInstance";
import { getCookie } from "../utils/cookieHandling";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { UserRoleContext } from "../utils/userAuthorization";
import { getCurrentLocation } from "../utils/functions";
import { useRef } from "react";
import ServiceDetail from "./ServiceDetail";
import { Button, IconButton, Tooltip } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../styles/components/offers-slider.scss";
import { Autoplay, Navigation, Pagination } from "swiper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}


function Home() {
  const gaEventTracker = useAnalyticsEventTracker('Home Page');
  const [categories, setCategories] = React.useState(null);
  const [locationInput, setLocationInput] = React.useState("")
  const [banners, setbanners] = React.useState(null)
  const roleContext = React.useContext(UserRoleContext);
  const history = useNavigate();

  const getAllCategories = async () => {
    var bodyFormData = new FormData();
    bodyFormData.append('is_web_login', true)
    const { data, status } = await axios.post("getserviceslist", bodyFormData);
    if (status === 200) {
      setCategories(data?.data[0]);
    }
  };

  const getBanners = async () => {
    var bodyFormData = new FormData();
    bodyFormData.append('is_web_login', true)
    const { data, status } = await axios.post("getofferlinks", bodyFormData);
    if (status === 200) {
      setbanners(data?.data)
    }
  };


  React.useEffect(() => {
    roleContext.getCartDetails();
    getAllCategories();
    let localStorageAddresses = JSON.parse(localStorage.getItem('addresses'))
    !localStorageAddresses?.length > 0 && localStorage.setItem('addresses', JSON.stringify([]));
  }, []);

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const apiKey = "AIzaSyCZ0OAV7Q-p6TbHLGPZGJY26QSJtj0dt50"
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (data.results.length > 0) {
              setLocationInput(data.results[0].formatted_address);
              localStorage.setItem("locationInput", data.results[0].formatted_address);
            } else {
              alert("No location found.");
            }
          })
          .catch((error) => {
            alert(`Error: ${error.message}`);
          });
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  React.useEffect(() => {
    localStorage.setItem("locationInput", locationInput.label || locationInput);
  }, [locationInput]);

  React.useEffect(() => {
    const storedAddress = localStorage.getItem("locationInput");
    if (storedAddress) {
      setLocationInput(storedAddress);
    }
  }, []);

  const handleClearLocation = () => {
    setLocationInput("");
    // localStorage.removeItem("locationInput");
  };

  const servicesRef = React.useRef(null);

  React.useEffect(() => {
    if (window.location.hash === "#services") {
      servicesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleServiceClick = () => {
    const services = document.getElementById('services');
    if (services) {
      services.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // const servicesRef = useRef(null);

  React.useEffect(() => {
    const storedAddress = localStorage.getItem("address");
    if (storedAddress) {
      setLocationInput(storedAddress);
    }
  }, []);

  React.useEffect(() => {
    roleContext.GetProfileDetails();
    getBanners()
  }, [])

  const onCategoryClick = (id) => {
    let found = false;
    categories?.items.map((item) => {
      item?.items[0].items.map((item2) => {
        if (item2?.main_service_id == id) {
          history.push({
            pathname: '/service-detail',
            state: item2
          })
          found = true
        }
      })
    })
    if (!found) {
      toast.warning('Service not found')
    }
  }

  return (
    <>
      <Header />
      <div className="app-banner">
        <Swiper
          slidesPerView="1"
          infinite={true}
          // navigation={true}
          // pagination={{
          //   clickable: true,
          // }}
          // centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}

          modules={[Autoplay, Navigation, Pagination]}
          className="OffersSwiper"
        >
          {/* {
            banners?.map((banner, index) => {
              return (
                <SwiperSlide>
                  <div key={index}
                    onClick={() => {
                      onCategoryClick(banner?.id)
                    }}>
                    <div className="">
                      <img src={banner?.link} />
                    </div>
                  </div>
                </SwiperSlide>
              )
            })
          } */}
          <SwiperSlide>
            <div className="">
              <img src="/images/banner-1.jpeg" />

            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="">
              <img src="/images/banner-2.jpeg" />

            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="">
              <img src="/images/banner-3.jpeg" />

            </div>
          </SwiperSlide>

        </Swiper>
        <div className="padding-20px">

          <div className="app-banner-search-new">
            <div>
              <Tooltip title="Click to get current location">
                <Button className="location-button" onClick={handleLocationClick}>
                  <img src="/images/gps.svg" />
                </Button>
              </Tooltip>
            </div>
            {!locationInput ? (
              <GooglePlacesAutocomplete
                value={locationInput}
                selectProps={{
                  className: "react-select-container",
                  classNamePrefix: "inner",
                  locationInput,
                  placeholder: 'Search for other locations...',
                  onChange: (e) => setLocationInput(e?.value?.description),
                }}
                apiKey="AIzaSyCZ0OAV7Q-p6TbHLGPZGJY26QSJtj0dt50"
                autocompletionRequest={{
                  location: { lat: 17.3850, lng: 78.4867 },
                  radius: 1000,
                  componentRestrictions: { country: "in" },
                  strictBounds: true,
                }}
              />
            ) : (
              <div className="location-box">
                <h3
                  className="location-box-display"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {locationInput?.substring(0, 60)}....
                </h3>
                <IconButton className="clear-button" onClick={handleClearLocation}>
                  <img src="/images/x-mark.svg" />
                </IconButton>
              </div>
            )}
          </div>
        </div>
      </div>
      <Container maxWidth="lg">
        <div id="services-scroll"></div>
        <div id="services" ref={servicesRef}>
          {categories && <ServicesMain categories={categories} id="services" />}
        </div>
      </Container>
      {/* <Footer handleServiceClick={handleServiceClick} /> */}
      {/* <div>
        <Container maxWidth="lg">
          <div className="offers-heading">
            <h1>Offers</h1>
            <p>Lorem Ipsum is simply dummy text of the printing</p>
          </div>
          <div className="offers-slider-wrapper">
            <OffersSlider />
          </div>
        </Container>
      </div> */}
      {/* <div className="app-special-product-service">
        <Container maxWidth="lg">
          <div className="offers-heading">
            <h1>Home Appliances</h1>
            <p>Lorem Ipsum is simply dummy text of the printing</p>
          </div>
          <div className="app-special-product-service-slider">
            <SpecialServicesSlider />
          </div>
        </Container>
      </div> */}
      <div className="app-download-section">
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="app-download-section-image">
                <img src="/images/Mobile-Mockup.png" />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="app-download-section-info">
                <div className="app-download-section-content">
                  <h1>We Provide All Your Home Services</h1>
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Viva mus viverra auctor velit.
                  </p> */}
                  <ul>
                    <li>
                      <img src="/images/google-play-badge.png" />
                    </li>
                    <li>
                      <img src="/images/appstore-badge.png" />
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Home;

