import * as React from "react";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import "../../styles/components/all-services.scss";
function UpcomingServices(){
    return(
        <>
            <div className="services-tab-content-wrapper">
                <div className="services-tab-content-heading">
                    <h1>Upcoming Services</h1>
                    <p>Lorem Ipsum is simply dummy text of the printing</p>
                </div>
                <ul className="services-tab-content-list">
                    <li className="services-tab-content-list-item">
                    <Link to="/service-detail">
                        <Button>
                            <img src="icons/carpenter-services.svg"/>
                            <span className="services-tab-content-list-item-title">Carpenter</span>
                        </Button>
                    </Link>
                    </li>
                    <li className="services-tab-content-list-item">
                    <Link to="/service-detail">
                        <Button>
                            <img src="icons/plumber-services.svg"/>
                            <span className="services-tab-content-list-item-title">Plumber Service</span>
                        </Button>
                    </Link>
                    </li>
                    <li className="services-tab-content-list-item">
                    <Link to="/service-detail">
                        <Button>
                            <img src="icons/electrican.svg"/>
                            <span className="services-tab-content-list-item-title">Electrician</span>
                        </Button>
                    </Link>
                    </li>
                    <li className="services-tab-content-list-item">
                    <Link to="/service-detail">
                        <Button>
                            <img src="icons/cleaning-services.svg"/>
                            <span className="services-tab-content-list-item-title">Cleaning Services</span>
                        </Button>
                    </Link>
                    </li>
                    <li className="services-tab-content-list-item">
                    <Link to="/service-detail">
                        <Button>
                            <img src="icons/home-appliances.svg"/>
                            <span className="services-tab-content-list-item-title">Home Appliances</span>
                        </Button>
                    </Link>
                    </li>
                    <li className="services-tab-content-list-item">
                    <Link to="/service-detail">
                        <Button>
                            <img src="icons/water-proofing.svg"/>
                            <span className="services-tab-content-list-item-title">Water Proofing</span>
                        </Button>
                    </Link>
                    </li>
                    <li className="services-tab-content-list-item">
                    <Link to="/service-detail">
                        <Button>
                            <img src="icons/pop-ceilling.svg"/>
                            <span className="services-tab-content-list-item-title">POP/False Ceiling</span>
                        </Button>
                    </Link>
                    </li>
                    <li className="services-tab-content-list-item">
                    <Link to="/service-detail">
                        <Button>
                            <img src="icons/fabrication.svg"/>
                            <span className="services-tab-content-list-item-title">Fabrication</span>
                        </Button>
                    </Link>
                    </li>
                    <li className="services-tab-content-list-item">
                    <Link to="/service-detail">
                        <Button>
                            <img src="icons/pest-control.svg"/>
                            <span className="services-tab-content-list-item-title">Pest Control</span>
                        </Button>
                    </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}
export default UpcomingServices;