import { Link, useNavigate } from "react-router-dom";
import { deleteAll, getCookie } from "../utils/cookieHandling";
import { useContext, useEffect, useState } from "react";
import { UserRoleContext } from "../utils/userAuthorization";
import axios from "../utils/axiosInstance";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Grid, Box, Container } from '@mui/material'
import { toast } from "react-toastify";

const Profile = () => {
  const localData = getCookie("token");

  const roleContext = useContext(UserRoleContext);

  let userdetails = roleContext.userdata

  const [userState, setUserState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    profile_pic: "",
  })

  const [selectedImage, setSelectedImage] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [imageData, setImageData] = useState(null)

  useEffect(() => {
    if (userdetails) {
      setUserState({
        first_name: userdetails.first_name,
        last_name: userdetails.last_name,
        email: userdetails.email,
        phone: userdetails.phone,
        profile_pic: userdetails.profile_pic,
      });
    } else {
      setUserState({});
    }
  }, [userdetails.first_name, userdetails.last_name,]);


  const onSubmit = async (e) => {
    e.preventDefault()
    var bodyFormData = new FormData();
    bodyFormData.append("token", localData?.token);
    bodyFormData.append("id", localData?.id);
    // bodyFormData.append('email', formData?.email);
    bodyFormData.append('firstName', userState?.first_name);
    bodyFormData.append('lastName', userState?.last_name);
    // bodyFormData.append('phone', formData?.phone);
    bodyFormData.append('profile_img', imageData);
    const { data, status } = await axios.post("ProfileUpdate", bodyFormData);
    if (status === 200) {
      toast.success("Profile updated successfully")
      roleContext.GetProfileDetails();
    }
  };

  let getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Called", reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
    });
  };

  const onImageChange = (e) => {
    e.preventDefault();
    let data = e.target.files[0];
    setSelectedImage(e.target.files[0])
    getBase64(data)
      .then((result) => {
        data["base64"] = result;
      })
      .then(async () => {
        setImageData(data?.base64)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage))
    }
  }, [selectedImage])

  useEffect(() => {
    roleContext.GetProfileDetails();
  }, [])

  return (
    <>
      <Header />
      <Container maxWidth={"lg"}>
        <div className="app-profile-edit-wrapper">
          <div className="app-login-content">
            <div className="app-login-content-wrapper">
              <div className="app-login-content-heading">
                <input hidden className="app-profile-edit-image-input" id="upload-profile" type="file" onChange={(e) => onImageChange(e)} accept="image/*" />
                <label htmlFor="upload-profile" className="app-profile-edit-image">
                  <img className="app-profile-edit-image-main" src={imageUrl && selectedImage ? imageUrl : userdetails?.profile_pic ? userdetails?.profile_pic : "/images/img.png"} />
                  <img className="app-profile-edit-image-icon" src="/images/camera.svg" />
                </label>
                <h5>{userdetails?.first_name} {userdetails?.last_name}</h5>
              </div>
              <form
                className="app-login-content-form">
                <div className="app-login-form-field">
                  <label>First name</label>
                  <input
                    type="text"
                    placeholder="Enter First name"
                    value={userState?.first_name}
                    onChange={(e) =>
                      setUserState({
                        ...userState,
                        first_name: e.target.value,
                      })
                    }
                  />
                  <span className="app-register-form-field-error">

                  </span>
                </div>
                <div className="app-login-form-field">
                  <label>Last name</label>
                  <input
                    type="text"
                    value={userState?.last_name}
                    placeholder="Enter Last name"
                    onChange={(e) =>
                      setUserState({
                        ...userState,
                        last_name: e.target.value,
                      })
                    }
                  />
                  <span className="app-register-form-field-error">

                  </span>
                </div>
                <div className="app-login-form-field">
                  <label>Mobile Number</label>
                  <input
                    readOnly
                    value={userdetails?.phone}
                  />
                  <span className="app-register-form-field-error">

                  </span>
                </div>
                <div className="app-login-form-field">
                  <label>Email</label>
                  <input
                    readOnly
                    value={userdetails?.email}
                  />
                  <span className="app-register-form-field-error">

                  </span>
                </div>

                <div className="app-profile-edit-button">
                  <Button
                    className="verify-button"
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={(e) => onSubmit(e)}
                  >
                    Update
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>

      </Container>
      <Footer />
    </>
  );
};
export default Profile;
