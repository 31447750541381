
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Link, useNavigate } from "react-router-dom";
import "../styles/layouts/header.scss";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { deleteAll, getCookie } from "../utils/cookieHandling";
import { useContext, useEffect, useState } from "react";
import { UserRoleContext } from "../utils/userAuthorization";
import axios from "../utils/axiosInstance";

const style = {
  position: "absolute",
  top: "15%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 700,
  background: "#FFFFFF",
  border: "1px solid #EDEDED",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
  borderRadius: "20px",
};


function Header({ totalCartItems }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open3 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open1, setOpen1] = React.useState(false);

  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const localData = getCookie("token");

  const overallQuantity = parseInt(localStorage.getItem('overallQuantity')) || 0;
  let cartLength = JSON.parse(localStorage.getItem('cartItems')) || [];


  const navigate = useNavigate();
  const roleContext = React.useContext(UserRoleContext);
  const logout = () => {
    roleContext.updateContext({ isLoggedIn: false });
    deleteAll();
    navigate("/login");
    window.location.reload()
  };
  const history = useNavigate()

  let userdetails = roleContext.userdata
  console.log(overallQuantity, parseInt(roleContext.cartList?.length),cartLength)

  return (
    <>
      <header className="app-header">
        <Container maxWidth="lg">
          <div className="app-header-content">
            <Link to="/">
              <img className="nvbar-brand" src="/images/logo.png" alt="jigydi logo" />
            </Link>
            <ul className="lg-header-items">
              <li className="d-sm-none header-nav-text">
                <Link to="/">
                  Home
                </Link>
              </li>
              <li className="d-sm-none header-nav-text">
                <a href="/#services-scroll">Services</a>
              </li>

              <li className="d-sm-none app-phpne-no">
                <a href="tel:040-46462222"><img src="/images/call.svg" /><span>040-46462222</span></a>
              </li>
              <li className="see-more-btn">
                <IconButton
                  id="basic-button"
                  aria-controls={open3 ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open3 ? 'true' : undefined}
                  onClick={handleClick2}
                >
                  <img src="/images/more.svg" />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl2}
                  open={open3}
                  onClose={handleClose2}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleClose2}>
                    <Link to="/">
                      Home
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose2}>
                    <a href="/#services-scroll">Services</a>
                  </MenuItem>
                  <MenuItem onClick={handleClose2}>
                    <a class="calltonumber" href="tel:040-46462222"><img src="/images/call.svg" /><span>040-46462222</span></a>
                  </MenuItem>
                </Menu>
              </li>
              <li>

                <Link to="/checkout">
                  <div className="cart-icon">
                    <img src="/images/shopping-cart.svg" />
                    <span>{localData?.token ? (parseInt(roleContext.cartList?.length) + cartLength?.length) : cartLength?.length}</span>
                  </div>
                </Link>
              </li>

              {roleContext.isLoggedIn || getCookie("token") ? (
                <li>
                  <Tooltip title="Menu">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <img className="profile-pic" src={userdetails?.profile_pic ? userdetails?.profile_pic : "/images/img.png"} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem onClick={() => history("/user_profile")}>Profile</MenuItem>
                    <MenuItem onClick={() => history("/history")}>My Bookings</MenuItem>
                    <MenuItem onClick={() => history("/address-list")}>Manage Address</MenuItem>
                    {/* <MenuItem>Customer Care</MenuItem> */}
                    <MenuItem className="logout-btn" onClick={logout}>Logout</MenuItem>
                  </Menu>
                </li>
              ) : (
                <li>
                  <Link to="/login">
                    <Button variant="contained" color="primary">
                      <span className="d-sm-none">Login / Sign Up</span>
                      <img className="login-btn-icon-sm" src="/images/login.svg" />
                    </Button>
                  </Link>
                </li>
              )}

            </ul>
          </div>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open1}
            onClose={handleClose1}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open1}>
              <Box sx={style}>
                <div className="app-slot-modal-wrapper">
                  <div className="app-slot-modal-header">
                    <h1>Do you really want to logout?</h1>
                    <Button
                      onClick={handleClose1}
                      className="modal-close-button"
                    >
                      <img src="/images/close.svg" />
                    </Button>
                  </div>

                  <div className="slot-order-button">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={
                        logout

                      }
                    >
                      yes
                    </Button>
                  </div>
                </div>

              </Box>
            </Fade>
          </Modal>
        </Container>
      </header>
    </>
  );
}
export default Header;
