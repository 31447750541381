import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import paiting1 from '../images/painting/home-interior-painting.svg';
import paiting2 from '../images/painting/house-exterior-painting.svg';
import paiting3 from '../images/painting/wall-textures.png';
import paiting4 from '../images/painting/royal-play.svg';
import paiting5 from '../images/painting/wallpaper.svg';
import paiting6 from '../images/painting/wood-coating.svg';
import { Container } from '@mui/material';
import roofwater from '../images/key-services/roof-waterproofing.png';
import basement from '../images/key-services/basement-waterproofing.png';
import wallwater from '../images/key-services/wall-waterproofing.png';
import terrace from '../images/key-services/terrace-waterproofing.png';
import experience from '../images/waterproofing-contractors/experience-expertise.svg'
import quality from '../images/waterproofing-contractors/quality-materials.svg'
import customer from '../images/waterproofing-contractors/customer-reviews.svg'
import professional from '../images/waterproofing-contractors/professionalism-timeliness.svg'
import warranty from '../images/waterproofing-contractors/warranty-after-sales-support.svg'
import linerbgoffer from '../how-we-work/we-offer-bg.svg'
import { Link } from 'react-router-dom';
  

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CreateNewTab = ()=>{
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Painting" {...a11yProps(0)} />
          <Tab label="Water proofing" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className='painting-images'>
        <Grid container spacing={2}>
        <Grid item xs={12}sm={12} md={4} lg={4}>
          <div className='painting-box painting-box-1'>
          <img src={paiting1} />
          <div className='painting-content'>
            <h4>Home Interior Painting</h4>
            <p>Transform your living spaces with our expert home interior painting services, blending style, color, and quality craftsmanship for lasting beauty.</p>
          </div>
          </div>
        </Grid>
        <Grid item xs={12}sm={12} md={4} lg={4}>
          <div className='painting-box painting-box-1'>
          <img src={paiting2} />
          <div className='painting-content'>
            <h4>House Exterior Painting</h4>
            <p>Revitalize your home's exterior with our expert painting services, enhancing curb appeal, protection, and longevity through skilled craftsmanship and quality materials.</p>
          </div>
          </div>
        </Grid>
        <Grid item xs={12}sm={12} md={4} lg={4}>
          <div className='painting-box painting-box-1'>
          <img src={paiting3} />
          <div className='painting-content'>
            <h4>Wall Textures</h4>
            <p>Elevate your walls with our diverse wall texture options, adding depth, character, and style to your interior spaces with expert craftsmanship.</p>
          </div>
          </div>
        </Grid>
        <Grid item xs={12}sm={12} md={4} lg={4}>
          <div className='painting-box'>
          <img src={paiting4} />
          <div className='painting-content'>
            <h4>Royal Play</h4>
            <p>Discover the elegance of Royal Play textures, transforming walls with intricate designs, rich textures, and timeless luxury for exceptional interiors.</p>
          </div>
          </div>
        </Grid>
        <Grid item xs={12}sm={12} md={4} lg={4}>
          <div className='painting-box'>
          <img src={paiting5} />
          <div className='painting-content'>
            <h4>Wallpaper</h4>
            <p>Elevate your space with our exquisite wallpaper collection, featuring a wide range of designs to enhance aesthetics and ambiance effortlessly.</p>
          </div>
          </div>
        </Grid>
        <Grid item xs={12}sm={12} md={4} lg={4}>
          <div className='painting-box'>
          <img src={paiting6} />
          <div className='painting-content'>
            <h4>Wood Coating</h4>
            <p>Enhance and protect your wood surfaces with our premium wood coating solutions, offering durability, beauty, and lasting preservation for your furnishings.</p>
          </div>
          </div>
        </Grid>
      </Grid>
      {/* <div className='linear-bg-what-we-offer'>
          <img src={linerbgoffer} />
        </div> */}
        </div>
        
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
     
        <div className='key-services'>
          <Container  maxWidth="lg">
            <div className='key-services-content'>
                <h2>Waterproofing Services</h2>
            </div>
            <div className='key-services-images'>
            <Grid container alignItems={'center'} spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <div className='roof-watering-image'>
                  <img src={roofwater} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className='key-services-image-content'>
                  <h2>Roof Waterproofing</h2>
                  <p>One of the critical areas of concern is the roof, as it is most exposed to rainwater. Waterproofing contractors employ various techniques, such as bituminous coatings, membrane systems, and polyurethane treatments, to seal the roof effectively. </p>
                  <div className='book-now'>
                    <Link to="/">
                      Book Now
                    </Link>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className='key-services-image-content'>
                  <h2>Basement Waterproofing</h2>
                  <p>Basements are particularly vulnerable to water seepage due to their below-ground location. Waterproofing contractors use specialized materials and techniques to create a water-resistant barrier, preventing water from seeping into the basement. </p>
                  <div className='book-now'>
                    <Link to="/">
                      Book Now
                    </Link>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className='roof-watering-image'>
                <img src={basement} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className='roof-watering-image'>
                  <img src={wallwater} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className='key-services-image-content'>
                  <h2>Wall Waterproofing</h2>
                  <p>Damp walls not only weaken the structure but also lead to the growth of mold and mildew. Professional waterproofing services offer solutions to keep the walls dry and well-protected.</p>
                  <div className='book-now'>
                    <Link to="/">
                      Book Now
                    </Link>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className='key-services-image-content'>
                  <h2>Terrace Waterproofing</h2>
                  <p>Terraces are also prone to water seepage, which can affect the ceiling below. Waterproofing contractors use advanced techniques to ensure the terrace remains water-resistant</p>
                  <div className='book-now'>
                    <Link to="/">
                      Book Now
                    </Link>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className='roof-watering-image'>
                  <img src={terrace} />
                </div>
              </Grid>
            </Grid>
            </div>
          </Container>
        </div>
        <div className='best-waterproofing'>
          <Container  maxWidth="lg">
            <div className='best-waterproofing-content'>
              <h2>Best Waterproofing Contractors</h2>
              <p>When searching for the best waterproofing services in Hyderabad, it is essential to consider the following factors.</p>
            </div>
            <div className='best-waterproofing-images'>
            <Grid container justifyContent={'center'} alignItems={'center'} spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <div className='experience-expertise'>
                  <div className='experience-expertise-image'>
                    <img src={experience} ></img>
                    <div className='experience-expertise-content'>
                      <h3>Experience and Expertise</h3>
                      <p>Look for contractors with substantial experience in waterproofing and a proven track record of successful projects.</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className='experience-expertise'>
                  <div className='experience-expertise-image'>
                    <img src={quality} ></img>
                    <div className='experience-expertise-content'>
                      <h3>Quality of Materials</h3>
                      <p>Inquire about the materials used by the contractors. High-quality materials ensure long-lasting results.</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className='experience-expertise'>
                  <div className='experience-expertise-image'>
                    <img src={customer} ></img>
                    <div className='experience-expertise-content'>
                      <h3>Customer Reviews</h3>
                      <p>Check online reviews and testimonials from previous customers to gauge the contractor's reputation and reliability.</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className='experience-expertise'>
                  <div className='experience-expertise-image'>
                    <img src={professional} ></img>
                    <div className='experience-expertise-content'>
                      <h3>Professionalism and Timeliness</h3>
                      <p>Choose a contractor known for their professionalism, punctuality, and adherence to project timelines.</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className='experience-expertise'>
                  <div className='experience-expertise-image'>
                    <img src={warranty} ></img>
                    <div className='experience-expertise-content'>
                      <h3>Warranty and After-Sales Support</h3>
                      <p>Ensure that the contractor offers a warranty on their services and provides after-sales support in case of any issues. </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            </div>
          </Container>
        </div>
      </CustomTabPanel>
    </Box>
  );
}

export default CreateNewTab;