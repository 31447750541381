import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import "swiper/css/bundle";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../styles/components/offers-slider.scss";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Container } from "@mui/system";

function OffersSlider({ banners }) {
  return (
    <>
      <Swiper
        slidesPerView="4"
        spaceBetween={10}
        navigation={true}
        // pagination={{
        //   clickable: true,
        // }}
        // centeredSlides={true}
        autoplay={{
        delay: 2500,
        disableOnInteraction: false,
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          900: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1199: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        }}
        modules={[Autoplay, Navigation, Pagination]}
        className="OffersSwiper"
      >
        {banners &&
          banners?.length &&
          banners?.map((item, index) => (
            <SwiperSlide>
              <div className="offers-slider-card">
                <img src={item} />
                {/* <div className="offers-slider-card-content">
                  <h6>single wall to full house</h6>
                  <p>Home Painting</p>
                  <span>Upto 15% off</span>
                </div> */}
              </div>
            </SwiperSlide>
          ))}

      
      </Swiper>
    </>
  );
}
export default OffersSlider;
