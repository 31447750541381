
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/layouts/header.scss";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { deleteAll, getCookie } from "../utils/cookieHandling";
import { useContext, useEffect, useState } from "react";
import { UserRoleContext } from "../utils/userAuthorization";
import axios from "../utils/axiosInstance";

const style = {
  position: "absolute",
  top: "15%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 700,
  background: "#FFFFFF",
  border: "1px solid #EDEDED",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
  borderRadius: "20px",
};


function HeaderService() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open3 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open1, setOpen1] = React.useState(false);

  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const localData = getCookie("token");

  const overallQuantity = parseInt(localStorage.getItem('overallQuantity')) || 0;


  const navigate = useNavigate();
  const roleContext = React.useContext(UserRoleContext);
  const logout = () => {
    roleContext.updateContext({ isLoggedIn: false });
    deleteAll();
    navigate("/login");
    window.location.reload()
  };
  const history = useNavigate()

  let userdetails = roleContext.userdata
  console.log(overallQuantity,parseInt(roleContext.cartList?.length))

  return (
    <>
      <header className="app-header">
        <Container maxWidth="lg">
          <div className="app-header-content">
            <Link to="/">
              <img className="nvbar-brand" src="/images/logo.png" alt="jigydi logo" />
            </Link>
            <ul className="lg-header-items">
            
              <li className="app-phpne-no">
                <a href="tel:040-46462222"><img src="/images/call.svg"/><span className="d-sm-none ">040-46462222</span></a>
              </li>
             
                <li>
                  <Link to="/">
                    <Button variant="contained" color="primary">
                      <span className="d-sm-none">Book Now</span>
                      <img className="login-btn-icon-sm" src="/images/login.svg"/>
                    </Button>
                  </Link>
                </li>
             
            </ul>
          </div>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open1}
            onClose={handleClose1}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open1}>
              <Box sx={style}>
                <div className="app-slot-modal-wrapper">
                  <div className="app-slot-modal-header">
                    <h1>Do you really want to logout?</h1>
                    <Button
                      onClick={handleClose1}
                      className="modal-close-button"
                    >
                      <img src="/images/close.svg" />
                    </Button>
                  </div>

                  <div className="slot-order-button">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={
                        logout

                      }
                    >
                      yes
                    </Button>
                  </div>
                </div>

              </Box>
            </Fade>
          </Modal>
        </Container>
      </header>
    </>
  );
}
export default HeaderService;
