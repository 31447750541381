import axios from "axios";
import { deleteAll } from "./cookieHandling";

const url = "https://jigydi.com/admin/app/";
// const url = "https://test.jigydi.com/admin/app/";

const instance = axios.create({
  baseURL: url,
  timeout: 10000,
});

instance.interceptors.response.use(
  (response) => {
    if (response?.data?.code === 404) {
      
    } else {
      return response;
    }
  },
  (error) => {
    if (error.response?.data?.code === 404) {
      alert("Not authorised");
    } else {
      alert("something went wrong");
    }
    throw error;
  }
);

// instance.interceptors.request.use((request) => {
//   request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
//   return request;
// });

export default instance;
