import * as React from "react";
import AllServices from "./AllServices";
import HomeServices from "./HomeServices";
import PersonalServices from "./PersonalServices";
import UpcomingServices from "./UpcomingServices";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "../../styles/components/services-main.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="" services-tabs-content>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ServicesMain({ categories }) {
  const [value, setValue] = React.useState(0);
  const [services, setServices] = React.useState(
    categories?.items ? categories?.items[0] : null
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // console.log("value", newValue);
    setServices(categories?.items[newValue]);
  };

  // console.log(categories, "services", services);

  return (
    <>
      <div className="services-wrapper">
        <div className="services-wrapper-content">
          <Tabs
            className="services-tabs-main"
            value={value}
            onChange={handleChange}
            aria-label="Services Tabs"
          >
            {categories &&
              categories?.items?.map((item, index) => (
                <Tab
                  className="services-tabs-label"
                  label={item?.name}
                  {...a11yProps(index)}
                />
              ))}

            {/* <Tab
              className="services-tabs-label"
              label="Personal"
              {...a11yProps(1)}
            />
            <Tab
              className="services-tabs-label"
              label="Home Services"
              {...a11yProps(2)}
            />
            <Tab
              className="services-tabs-label"
              label="Upcoming Services"
              {...a11yProps(3)}
            /> */}
          </Tabs>
        </div>

        {/* {services &&
          services?.items[0]?.items?.map((item) => (
            <TabPanel value={value} index={value}>
              <AllServices item={item} />
            </TabPanel>
          ))} */}

        <TabPanel value={value} index={value}>
          <AllServices services={services} />
        </TabPanel>

        {/* <TabPanel value={value} index={value}>
          <PersonalServices />
        </TabPanel>
        <TabPanel value={value} index={value}>
          <HomeServices />
        </TabPanel>
        <TabPanel value={value} index={value}>
          <UpcomingServices />
        </TabPanel> */}
      </div>
    </>
  );
}
