import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import "../styles/layouts/footer.scss";
import { useRef } from "react";
import Home from "../pages/Home";
import { Link, useNavigate } from "react-router-dom";

function Footer() {

  let Navigate = useNavigate()

  const handleClick = () => {
    const servicesRef = document.getElementById("services");
    servicesRef.scrollIntoView({ behavior: "smooth" });
  };

  const handleServiceClick = () => {
    if (
      window.location.pathname === "/home" ||
      window.location.pathname === "/"
    ) {
      handleClick();
    } else {
      if (window.location.href === "http://localhost:3000/#services") {
        Navigate('/home')
        // window.location.reload();
      } else {
        // window.location.href = "http://localhost:3000/#services";
        Navigate('/home')
      }
    }
  };

  return (
    <>
      <footer className="app-footer">
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4.5} xl={4.5}>
              <div
                className="app-footer-brand"
               
              >
                <div  style={{ display: "flex", flexDirection: "row" }}>
                <img
                  src="/images/jigydi-logo.png"
                  alt="jigydi logo png"
                  style={{ width: "15%" }}
                />
                <img src="/images/jigydi-footer-logo.svg"></img>

                </div>
                <p>Jigydi Home Care Services <br />
                    LIG B-320, 1st Floor,
                    Near SBI Bank, <br />
                    Dr.A.S.Rao Nagar, <br />
                    Hyderabad-62 . <br /></p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={2.5} xl={2.5}>
            <div className="app-footer-quicklink">
                <h3>Quick Links</h3>
                <ul>
                  <a  href="/#services-scroll">
                    <li>Services</li>
                  </a>
                  <Link to="/privacy-policy">
                    <li>Privacy Policy</li>
                  </Link>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={2.5} xl={2.5}>
              <div className="app-footer-quicklink">
                <h3>Contact Us</h3>
                <ul>
                  {/* <li>About Us</li>
                   <li onClick={handleServiceClick} style={{cursor:'pointer'}}>
                      Services
                  </li>
                  <li>Blog</li>
                  <li>Contact Us</li> */}
                  <a href="tel:040-46462222">
                    <li>040-46462222</li>
                  </a>
                  <a href="mailto:jigydi@gmail.com">
                    <li>jigydi@gmail.com</li>
                  </a>
                  {/* <Link to="/privacy-policy">
                    <li>Privacy Policy</li>
                  </Link> */}
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={2.5} xl={2.5}>
              <div className="app-footer-quicklink">
                <h3>Publication</h3>
                <ul>
                  <a
                    href="https://www.facebook.com/jigydi?mibextid=LQQJ4d"
                    target="_blank"
                  >
                    <li>
                      <img src="/images/facebook.svg" />
                      <span>Facebook</span>
                    </li>
                  </a>
                  <a
                    href="https://in.linkedin.com/company/jigydi-home-services"
                    target="_blank"
                  >
                    <li>
                      <img
                        src="/images/linkedin.svg"
                        style={{ width: "16px", height: "12px" }}
                      />
                      <span>LinkedIn</span>
                    </li>
                  </a>
                  <a
                    href="https://instagram.com/jigydihomeservices?igshid=YmMyMTA2M2Y"
                    target="_blank"
                  >
                    <li>
                      <img src="/images/instagram.svg" />
                      <span>Instagram</span>
                    </li>
                  </a>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="app-footer-bottom">
          <Container maxWidth="lg">
            <p>© 2023 Jigydi - All Rights Reserved</p>
          </Container>
        </div>
      </footer>
    </>
  );
}
export default Footer;
