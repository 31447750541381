
import * as React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "../layouts/Header";
import "../styles/pages/checkout.scss";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Footer from "../layouts/Footer";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { UserRoleContext } from "../utils/userAuthorization";
import { getCartId, getCartQuantityById } from "../utils/functions";
import { deleteCookie, getCookie } from "../utils/cookieHandling";
import axios from "../utils/axiosInstance";
import useRazorpay from "react-razorpay";
import moment from "moment/moment";
import AddAddress from "../components/Address";
import LoginModel from "../components/LoginModel";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 700,
  background: "#FFFFFF",
  border: "1px solid #EDEDED",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
  borderRadius: "20px",
};

function ServiceDetail({ props }) {
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const { cartList, cartTotal } = React.useContext(UserRoleContext);
  const roleContext = React.useContext(UserRoleContext);

  const [startDate, setStartDate] = React.useState(null);
  const [allFiveDays, setAllFiveDays] = React.useState([]);
  const [times, setTimes] = React.useState(null);
  const navigate = useNavigate();
  const Razorpay = useRazorpay();

  const storedLocationInput = localStorage.getItem("locationInput");

  const items = JSON.parse(localStorage.getItem("cartItems")) || [];

  const overallRate = localStorage.getItem("overallRate") || 0;

  // console.log("local storage", items);

  const handlePayment = async (params) => {
    if (times == null || startDate == null) {
      alert("please select date and time");
      return;
    }

    var bodyFormData = new FormData();
    const localData = getCookie("token");

    bodyFormData.append("phone", localData?.phone);
    bodyFormData.append("token", localData?.token);
    bodyFormData.append("id", localData?.id);
    bodyFormData.append("amount", roleContext?.cartTotal);

    const res = await axios.post("Createorderrazorpay", bodyFormData);
    console.log('resssssssss====>', res)
    let order_id
    if (res?.status == 200) {
      order_id = res?.data?.data?.id
    }
    const options = {
      key: "rzp_live_q0j3pyE5QeZpc0", // Enter the Key ID generated from the Dashboard
      // key: "rzp_test_ltqQZtxwNcWQY0", // Enter the Key ID generated from the Dashboard
      amount: (roleContext?.cartTotal * 100).toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Jigydi",
      description: "please make payment to confirm the order",
      image: "https://www.jigydi.com/images/logo.png",

      // order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        createOrder(order_id);
        // createOrder(response.razorpay_payment_id);
      },
      prefill: {
        name: "Jigydi Home Care Services",
        email: "jigydi@gmail.com",
        contact: "040-46462222",
      },
      notes: {
        address: "LIG B-320,1st Floor, Near SBI Bank, Dr.A.S.Rao Nagar, Hyderabad-62",
      },
      theme: {
        color: "#3399cc",
      },
      "modal": {
        escape: false,
        "ondismiss": function () {
          window.location.replace("/checkout");
        }
      }
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      // alert("hi2");
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };


  const getNextFiveDays = () => {
    let fiveDays = [];
    for (var i = 0; i < 5; i++) {
      const today = new Date();
      // to return the date number(1-31) for the specified date
      // console.log("today => ", today);
      let tomorrow = new Date();
      tomorrow.setDate(today.getDate() + i + 1);
      //returns the tomorrow date
      fiveDays.push(tomorrow);
    }
    setAllFiveDays([...fiveDays]);
    // console.log(fiveDays);
  };

  const days = ["M", "T", "W", "T", "F", "S", "S"];

  const timeList = [
    "9:00 AM - 11:00 AM",
    "11:00 AM - 13:00 PM",
    "13:00 PM - 15:00 PM",
    "15:00 PM - 17:00 PM",
    "17:00 PM - 19:00 PM",
  ];

  const localData = getCookie("token");
  const [currentLocationNew, setCurrentLocation] = React.useState(null)

  const createOrder = async (id) => {
    var bodyFormData = new FormData();
    const localData = getCookie("token");

    bodyFormData.append("phone", localData?.phone);
    bodyFormData.append("token", localData?.token);
    bodyFormData.append("id", localData?.id);
    bodyFormData.append("transaction_status", id ? "success" : 'null');
    bodyFormData.append("transaction_id", id ?? '');
    bodyFormData.append("landmark", "test");
    bodyFormData.append(
      "preferable_date",
      moment(new Date(startDate)).format("DD-MM-YYYY")
    );


    bodyFormData.append("preferable_time", times);
    if (items && items.length) {
      bodyFormData.append("cart_data", JSON.stringify(items));
    }
    bodyFormData.append("amount", (roleContext?.cartTotal).toString());
    bodyFormData.append("address", roleContext?.location?.address);
    bodyFormData.append("lat", roleContext?.location?.latitude);
    bodyFormData.append("lng", roleContext?.location?.longitude);
    bodyFormData.append("is_new_app", "true");
    const { status } = await axios.post("createorder", bodyFormData);
    if (status === 200) {
      roleContext.getCartDetails();
      deleteCookie("cartItems")
      deleteCookie("overallRate")
      navigate("/")
      toast.success("order created successfully")
      handleClose2();
    }
  };

  React.useEffect(() => {
    getNextFiveDays();
  }, []);


  const increaseOrDecrease = async (type, id) => {
    var bodyFormData = new FormData();
    const localData = getCookie("token");
    bodyFormData.append("phone", localData?.phone);
    bodyFormData.append("token", localData?.token);
    bodyFormData.append("id", localData?.id);
    bodyFormData.append("is_web_login", true);
    bodyFormData.append("type", type);
    bodyFormData.append("cart_id", id);
    // bodyFormData.append("type", selectedCategory);
    const { data, status } = await axios.post("quantity", bodyFormData);
    if (status === 200) {
      roleContext.getCartDetails();
    }
  };

  const removeFromCart = async (id) => {
    var bodyFormData = new FormData();
    const localData = getCookie("token");
    bodyFormData.append("phone", localData?.phone);
    bodyFormData.append("token", localData?.token);
    bodyFormData.append("id", localData?.id);

    bodyFormData.append("cart_id", id);
    // bodyFormData.append("type", selectedCategory);
    const { data, status } = await axios.post("Removefromcart", bodyFormData);
    if (status === 200) {
      roleContext.getCartDetails();
    }
  };



  const increaseOrDecreaseLocalCart = async (type, id) => {
    let cartItems = [];


    cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const existingItem = cartItems.find((item) => item.service_id === id);
    if (existingItem) {
      if (type === "increase") {
        existingItem.quantity += 1;
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
      } else if (type === "decrease") {
        if (existingItem.quantity > 1) {
          existingItem.quantity -= 1;
          localStorage.setItem("cartItems", JSON.stringify(cartItems));
        } else if (existingItem.quantity === 1) {
          removeFromCart(id);
          return;
        }
      }
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      roleContext.setLocalCart(cartItems);
      const overallQuantity = cartItems.reduce(
        (total, item) => total + item.quantity,
        0
      );

      const overallRate = cartItems.reduce(
        (totalRate, item) => totalRate + item.rate * item.quantity,
        0
      );
      console.log("increase or decrease", overallQuantity)
      console.log("increase or decrease", overallRate)
      localStorage.setItem("overallRate", overallRate.toString());
      localStorage.setItem("overallQuantity", overallQuantity.toString());

    }
  };

  const removeFromLocalCardCart = async (id) => {


    let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const itemIndex = cartItems.findIndex((item) => item.service_id === id);
    if (itemIndex > -1) {
      if (cartItems[itemIndex].quantity === 1) {
        cartItems.splice(itemIndex, 1);
      } else {
        cartItems[itemIndex].quantity -= 1;
      }

      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      roleContext.setLocalCart(cartItems);

      const overallQuantity = cartItems.reduce(
        (total, item) => total + item.quantity,
        0
      );

      const overallRate = cartItems.reduce(
        (totalRate, item) => totalRate + item.rate * item.quantity,
        0
      );
      localStorage.setItem("overallQuantity", overallQuantity.toString());
      localStorage.setItem("overallRate", overallRate.toString());
    }

  };

  const history = useNavigate();
  const location = useLocation();
  let addressState = location
  const [addresses, setaddresses] = React.useState([]);

  console.log(addressState)

  // const roleContext = useContext(UserRoleContext);
  const getAllCategories = async () => {
    const localData = getCookie("token");
    if (localData?.token) {
      var bodyFormData = new FormData();
      bodyFormData.append("phone", localData?.phone);
      bodyFormData.append("token", localData?.token);
      bodyFormData.append("id", localData?.id);
      // bodyFormData.append("type", selectedCategory);
      const { data, status } = await axios.post("getaddress", bodyFormData);
      if (status === 200) {
        let address = JSON.parse(localStorage.getItem('addresses'))
        let localAddresses = address?.length > 0 ? [...address] : []
        let Arr = [...data?.data, ...localAddresses]
        console.log(data.data, localAddresses, Arr)
        setaddresses(Arr);
        // console.log(data.data)
        localStorage.setItem(
          "lastAddress",
          JSON.stringify(data?.data[data?.data.length - 1])
        );
        // setCategories(data?.data[0]);
      }
    } else {
      let address = JSON.parse(localStorage.getItem('addresses'))
      setaddresses(address);
    }


  };



  React.useEffect(() => {
    roleContext.getCartDetails();
    getAllCategories();
  }, []);

  const handleLocationClick = (from) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const apiKey = "AIzaSyCZ0OAV7Q-p6TbHLGPZGJY26QSJtj0dt50"
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (data.results.length > 0) {
              if (from == "func") {
                setSelected("currentLocation");
                setCurrentLocation({ latitude: latitude, longitude: longitude, address: data.results[0].formatted_address })
                roleContext.setLocation({ latitude: latitude, longitude: longitude, address: data.results[0].formatted_address })
              } else {
                setCurrentLocation({ latitude: latitude, longitude: longitude, address: data.results[0].formatted_address })
              }

            } else {
              alert("No location found.");
            }
          })
          .catch((error) => {
            alert(`Error: ${error.message}`);
          });
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  const [selected, setSelected] = React.useState(null);

  const handleItemClick = (event, addressId, item) => {
    const isChecked = event.target.checked;
    if (addressId == "currentLocation") {
      setSelected("currentLocation");
      if (currentLocationNew == null) {
        handleLocationClick("func");
      }
      roleContext.setLocation(currentLocationNew)

      return;
    }
    if (isChecked) {
      roleContext.setLocation({ latitude: item?.lat, longitude: item?.lng, address: item?.address })
      setSelected(addressId);
    } else {
      setSelected(null);
    }
  };

  React.useEffect(() => {
    handleLocationClick("useEffect");
  }, [])

  console.log('itemmmmm===>', cartTotal)

  return (
    <>
      <div className="service-detail-header">
        <Header />
      </div>
      {items.map((item) => {
        <div className="checkout-order-summary" key={item.service_id}>
          <div className="checkout-order-summary-info">
            <div className="checkout-page-address-home">
              <span>{item?.service_name}</span>
            </div>
            <p>₹ {item?.rate} </p>
          </div>
        </div>;
      })}
      <div className="checkout-page-wrapper">
        <Container maxWidth="lg">
          <h1 className="checkout-page-wrapper-heading">Check Out</h1>
          <div className="checkout-page-wrapper-content">
            <Grid container spacing={10}>
              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                <div className="checkout-page-address">
                  <h1>Shipping Address</h1>
                  <Button style={{ marginRight: 100 }} variant="contained" onClick={() => { navigate("/add-address") }} color="primary" className="checkout-page-address-edit">
                    Add New
                  </Button>
                  {localData?.token && <Button variant="contained" onClick={() => { navigate("/address-list") }} color="primary" className="checkout-page-address-edit">
                    Edit
                  </Button>}
                  {!storedLocationInput && addresses.length <= 0 ? (
                    <h5>Please provide location</h5>
                  ) : (
                    <div>
                      <div className="d-flex flex-column">
                        {

                          addresses?.map((item, index) => {
                            const addressId = item?.id; // Assuming each address has a unique identifier
                            const isSelected = selected === addressId;

                            return (
                              <>
                                <div className="app-address-divide">
                                  <div className="checkout-page-address-home">
                                    <label htmlFor={`locationCheckbox-${index}`} key={index} className={` ${isSelected ? 'selected' : ''}`}>
                                      <input
                                        type="checkbox"
                                        id={`locationCheckbox-${index}`}
                                        className="checkbox-input"
                                        checked={isSelected}
                                        onChange={(event) => handleItemClick(event, addressId, item)}
                                      />
                                    </label>
                                    <span> {item?.type == 1 ? "Home" : item?.type == 2 ? "Office" : "Others"}</span>
                                  </div>
                                  <p> {item?.address}, {item?.city}, {item?.state}, {item?.pincode}</p>
                                </div>

                              </>
                            );
                          })

                        }
                        <div className="app-address-divide">
                          <div className="checkout-page-address-home">
                            <label htmlFor={`locationCheckbox-${500}`} className={` ${selected == "currentLocation" ? 'selected' : ''}`}>
                              <input
                                type="checkbox"
                                id={`locationCheckbox-${500}`}
                                className="checkbox-input"
                                checked={selected == "currentLocation"}
                                onChange={(event) => { handleItemClick(event, "currentLocation", null) }}
                              />
                            </label>
                            <span> Current Location</span>
                          </div>
                          <p> {currentLocationNew?.address ?? ""}</p>
                        </div>
                      </div>
                    </div>
                  )}

                </div>


                <div className="checkout-page-summary checkout-page-address">
                  <h1>Order Summary</h1>
                  {items?.map((item) => {
                    return <div
                      className="checkout-order-summary"
                      key={item.service_id}
                    >
                      <div className="checkout-order-summary-info">
                        <div className="checkout-page-address-home">
                          <span>{item?.service_name}</span>
                        </div>
                        <p>₹ {item?.rate}</p>
                        {/* <p>Quantity: {item.quantity}</p> */}
                      </div>
                      <div className="checkout-order-summary-price">

                        <div className="add-items-count-wrapper">
                          <Button
                            className="add-items-trigger-inn"
                            onClick={() => {

                              if (parseInt(item.quantity) === 1) {
                                removeFromLocalCardCart(item.service_id);
                                return;
                              }
                              increaseOrDecreaseLocalCart(
                                "decrease",
                                item.service_id,
                                items
                              );

                            }}
                          >
                            <img src="/images/minus.svg" />
                          </Button>
                          <input
                            className="add-items-show-input-alt"
                            readOnly
                            value={
                              // itemQuantities
                              item.quantity
                            }

                          />
                          <Button
                            className="add-items-trigger-inn"
                            onClick={() => {

                              increaseOrDecreaseLocalCart(
                                "increase",
                                item.service_id,
                                items
                              );

                            }}
                          >
                            <img src="/images/plus.svg" />
                          </Button>
                        </div>
                        {/* <span className="checkout-order-summary-price-info">
                          <img src="/images/price-symbol.svg" /> 
                        </span> */}
                      </div>
                    </div>;
                  })}
                  {
                    cartList?.map((item, index) => {
                      return <div className="checkout-order-summary" key={index}>
                        <div className="checkout-order-summary-info">
                          <div className="checkout-page-address-home">
                            <span>{item?.service_name}</span>
                          </div>
                          <p>₹ {item?.rate}</p>
                          {/* <p>₹ {item?.rate}  x {item?.quantity}</p> */}
                        </div>
                        <div className="checkout-order-summary-price">

                          <div className="add-items-count-wrapper">
                            <Button
                              className="add-items-trigger-inn"
                              onClick={() => {
                                if (
                                  parseInt(
                                    getCartQuantityById(
                                      roleContext?.cartList,
                                      item?.service_id,
                                      "service_id"
                                    )
                                  ) == 1
                                ) {
                                  removeFromCart(
                                    getCartId(
                                      roleContext?.cartList,
                                      item?.service_id,
                                      "service_id"
                                    )
                                  );
                                  return;
                                }
                                increaseOrDecrease(
                                  "decrease",
                                  getCartId(
                                    roleContext?.cartList,
                                    item?.service_id,
                                    "service_id"
                                  )
                                );
                              }}
                            >
                              <img src="/images/minus.svg" />
                            </Button>
                            <input
                              className="add-items-show-input-alt"
                              readOnly
                              value={item?.quantity}
                              maxLength={2}
                            />
                            <Button
                              className="add-items-trigger-inn"
                              onClick={() => {
                                increaseOrDecrease(
                                  "increase",
                                  getCartId(
                                    roleContext?.cartList,
                                    item?.service_id,
                                    "service_id"
                                  )
                                );
                              }}
                            >
                              <img src="/images/plus.svg" />
                            </Button>
                          </div>
                          {/* <span className="checkout-order-summary-price-info">
                          <img src="/images/price-symbol.svg" /> 
                        </span> */}
                        </div>
                      </div>
                    }

                    )
                  }


                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                <div className="checkout-payment-sticky">
                  <div className="checkout-payment-summary">
                    <h1 className="checkout-payment-summary-title">
                      Payment Summary
                    </h1>
                    <ul className="checkout-payment-summary-list">
                      <li>
                        <h6>Item Total</h6>

                        <span>₹ {parseInt(cartTotal) == 0 ? (overallRate == null || overallRate == "null" ? 0 : parseInt(overallRate ?? 0)) : parseInt(cartTotal)}</span>
                        {/* <span>₹ {parseInt(cartTotal ?? 0) + (overallRate == null || overallRate == "null" ? 0 : parseInt(overallRate ?? 0))}</span> */}

                      </li>
                      {/* <li>
                        <h6>Item Total</h6>
                        <span>
                          <img src="/images/price-symbol.svg" /> {cartTotal}
                        </span>
                      </li> */}
                    </ul>
                  </div>
                  <div className="service-addto-cart">
                    <h6>
                      <img src="/images/price-symbol.svg" />
                      <span>{parseInt(cartTotal) == 0 ? (overallRate == null || overallRate == "null" ? 0 : parseInt(overallRate ?? 0)) : parseInt(cartTotal)}</span>
                    </h6>

                    <Button
                      onClick={() => {
                        if (localData?.token) {
                          if (!selected) {
                            toast.error("Please select address");
                            return;
                            // } else if (currentLocationNew == null) {
                            //   toast.error("Please select address");
                            //   return;
                          } else if (cartList?.length == 0) {
                            toast.error("Oops...!, Your Cart was not Loaded...");
                            return;
                          }
                          handleOpen1();
                        } else {
                          navigate("/login");
                        }
                      }}
                      variant="contained"
                      color="primary"

                    // disabled={cartList.length === 0}
                    >
                      Book Slot
                    </Button>

                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open1}
                      onClose={handleClose1}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <Fade in={open1}>
                        <Box sx={style}>
                          <div className="app-slot-modal-wrapper">
                            <div className="app-slot-modal-header">
                              <h1>Schedule</h1>
                              <Button
                                onClick={handleClose1}
                                className="modal-close-button"
                              >
                                <img src="/images/close.svg" />
                              </Button>
                            </div>
                            <div className="app-slot-modal-body">
                              <div className="app-slot-modal-body-description">
                                <h2>Pick Date & Time</h2>
                                <p>
                                  When would you like us to come to your address
                                  ?
                                </p>
                              </div>
                              <div className="app-slot-modal-body-calender">
                                <h4>
                                  {startDate
                                    ? moment(new Date(startDate)).format(
                                      "DD MMMM YYYY"
                                    )
                                    : "Select Date"}
                                </h4>
                                <ul className="app-slot-calender-dates">
                                  {allFiveDays.map((item) => {
                                    return (
                                      <li>
                                        <Button
                                          style={{ display: "inline" }}
                                          // className="calender-dates-button calender-dates-button-selected"
                                          className={`calender-dates-button ${startDate == item
                                            ? "calender-dates-button-selected"
                                            : ""
                                            }`}
                                          onClick={() => setStartDate(item)}
                                        >
                                          <span style={{ display: "block" }}>
                                            {
                                              days[
                                              new Date(item).getDay() - 1 ==
                                                -1
                                                ? 6
                                                : new Date(item).getDay() - 1
                                              ]
                                            }
                                          </span>
                                          <span>
                                            {new Date(item).getDate()}
                                          </span>
                                        </Button>
                                      </li>
                                    );
                                  })}
                                </ul>
                                <ul className="app-slot-calender-time-slot">
                                  {timeList.map((item, index) => {
                                    return (
                                      <li>
                                        <Button
                                          // className="slot-time-button slot-time-button-selected"
                                          className={`slot-time-button ${times == index
                                            ? "slot-time-button-selected"
                                            : ""
                                            }`}
                                          onClick={() => setTimes(index)}
                                        >
                                          <span>{item}</span>
                                        </Button>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>

                              <div className="slot-order-button">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    if (times == null || startDate == null) {
                                      toast.error("Please select date and time");
                                      return
                                    }


                                    handleClose1();
                                    handleOpen2();

                                  }}
                                >
                                  Place Order
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </Fade>
                    </Modal>



                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open2}
                      onClose={handleClose2}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <Fade in={open2}>
                        <Box sx={style}>
                          <div className="app-slot-modal-wrapper">
                            <div className="app-slot-modal-header">
                              <h1>Payment Options</h1>
                              <Button
                                onClick={handleClose2}
                                className="modal-close-button"
                              >
                                <img src="/images/close.svg" />
                              </Button>
                            </div>
                            <div
                              className="app-slot-modal-body"
                              style={{ padding: "10px" }}
                            >
                              <p>Please select any one payment option</p>
                              <div className="slot-order-button">
                                <Button
                                  style={{ margin: "10px" }}
                                  variant="contained"
                                  color="primary"
                                  className="app-btn-disabled"
                                  disabled
                                  onClick={() => {


                                    handlePayment();

                                  }}
                                >
                                  Pay online in advance
                                </Button>
                                <Button
                                  style={{ margin: "10px" }}
                                  variant="contained"
                                  color="primary"
                                  onClick={() => { createOrder(null); }}
                                >
                                  Pay after service
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </Fade>
                    </Modal>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
        {/* <LoginModel handleClose3={() => {}} open={true} /> */}
      </div>
      <Footer />
    </>
  );
}
export default ServiceDetail;
