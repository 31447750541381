
import * as React from "react";
import Header from "../layouts/Header";
import OffersSlider from "../components/OffersSlider";
import SpecialServicesSlider from "../components/SpecialServicesSlider";
import "../styles/pages/service-detail.scss";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "../icons/DownChevron";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../layouts/Footer";
import {
  findIndexValue,
  getCartId,
  getCartQuantityById,
} from "../utils/functions";
import { getCookie } from "../utils/cookieHandling";
import axios from "../utils/axiosInstance";
import { UserRoleContext } from "../utils/userAuthorization";

function ServiceDetail() {
  const [expanded, setExpanded] = React.useState(0);

  const location = useLocation();
  const roleContext = React.useContext(UserRoleContext);

  let items = location?.state?.items;

  let navigate = useNavigate()

  let main_service_id = location?.state?.main_service_id;

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const itemss = JSON.parse(localStorage.getItem("cartItems")) || [];

  const overallQuantity = parseInt(localStorage.getItem("overallQuantity")) || 0;

  const overallRate = parseInt(localStorage.getItem("overallRate")) || 0;

  const [cartItems, setCartItems] = React.useState([]);

  React.useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    roleContext.setLocalCart(cartItems)
    setCartItems(itemss);
  }, []);

  const localData = getCookie("token");



  const addItemToCart = async (id, rate, name) => {
    var bodyFormData = new FormData();
    const localData = getCookie("token");
    bodyFormData.append("is_web_login", true)
    if (localData?.token) {
      bodyFormData.append("phone", localData?.phone);
      bodyFormData.append("token", localData?.token);
      bodyFormData.append("id", localData?.id);
      bodyFormData.append("quantity", "1");
      bodyFormData.append("service_id", id);
      bodyFormData.append("rate", rate);
      bodyFormData.append("main_service_id", main_service_id);
      // bodyFormData.append("type", selectedCategory);
      const { data, status } = await axios.post("Addtocart", bodyFormData);
      if (status === 200) {
        roleContext.getCartDetails();
      }
    } else {
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      var bodyFormData = new FormData();
      const existingItem = cartItems.find((item) => item.service_id === id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        const newItem = {
          service_id: id,
          rate: rate,
          service_name: name,
          quantity: 1,
          main_service_id: main_service_id
        };
        cartItems.push(newItem);
      }
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      roleContext.setLocalCart(cartItems);
      const overallQuantity = cartItems.reduce(
        (total, item) => total + item.quantity,
        0
      );

      const overallRate = cartItems.reduce(
        (totalRate, item) => totalRate + item.rate * item.quantity,
        0
      );

      localStorage.setItem("overallQuantity", overallQuantity.toString());
      localStorage.setItem("overallRate", overallRate.toString());
    }
  };

  const increaseOrDecrease = async (type, id) => {
    var bodyFormData = new FormData();
    const localData = getCookie("token");
    bodyFormData.append("is_web_login", true)
    let cartItems = [];

    if (localData?.token) {
      bodyFormData.append("phone", localData?.phone);
      bodyFormData.append("token", localData?.token);
      bodyFormData.append("id", localData?.id);
      bodyFormData.append("type", type);
      bodyFormData.append("cart_id", id);
      // bodyFormData.append("type", selectedCategory);
      const { data, status } = await axios.post("quantity", bodyFormData);
      if (status === 200) {
        roleContext.getCartDetails();
      }
    } else {
      cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const existingItem = cartItems.find((item) => item.service_id === id);
      if (existingItem) {
        if (type === "increase") {
          existingItem.quantity += 1;
          localStorage.setItem("cartItems", JSON.stringify(cartItems));
        } else if (type === "decrease") {
          if (existingItem.quantity > 1) {
            existingItem.quantity -= 1;
            localStorage.setItem("cartItems", JSON.stringify(cartItems));
          } else if (existingItem.quantity === 1) {
            removeFromCart(id);
            return;
          }
        }
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
        roleContext.setLocalCart(cartItems);
        const overallQuantity = cartItems.reduce(
          (total, item) => total + item.quantity,
          0
        );

        const overallRate = cartItems.reduce(
          (totalRate, item) => totalRate + item.rate * item.quantity,
          0
        );
        console.log("increase or decrease", overallQuantity)
        console.log("increase or decrease", overallRate)
        localStorage.setItem("overallRate", overallRate.toString());
        localStorage.setItem("overallQuantity", overallQuantity.toString());
      }
    }
  };

  const removeFromCart = async (id) => {
    var bodyFormData = new FormData();
    const localData = getCookie("token");
    bodyFormData.append("is_web_login", true)
    if (localData?.token) {
      bodyFormData.append("phone", localData?.phone);
      bodyFormData.append("token", localData?.token);
      bodyFormData.append("id", localData?.id);
      bodyFormData.append("cart_id", id);
      // bodyFormData.append("type", selectedCategory);
      const { data, status } = await axios.post("Removefromcart", bodyFormData);
      if (status === 200) {
        roleContext.getCartDetails();
      }
    } else {
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const itemIndex = cartItems.findIndex((item) => item.service_id === id);
      if (itemIndex > -1) {
        if (cartItems[itemIndex].quantity === 1) {
          cartItems.splice(itemIndex, 1);
        } else {
          cartItems[itemIndex].quantity -= 1;
        }

        localStorage.setItem("cartItems", JSON.stringify(cartItems));
        roleContext.setLocalCart(cartItems);

        const overallQuantity = cartItems.reduce(
          (total, item) => total + item.quantity,
          0
        );

        const overallRate = cartItems.reduce(
          (totalRate, item) => totalRate + item.rate * item.quantity,
          0
        );
        localStorage.setItem("overallQuantity", overallQuantity.toString());
        localStorage.setItem("overallRate", overallRate.toString());
      }
    }
  };


  const conditionForLocal = (id) => {
    const localData = getCookie("token");
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    // roleContext.setLocalCart(cartItems)
    const existingItem = roleContext?.localCart.findIndex((item) => item.service_id === id);
    if (localData?.token) {
      if (
        findIndexValue(
          roleContext?.cartList,
          id,
          "service_id"
        ) < 0
      ) {
        return true;
      }
    } else {
      if (existingItem >= 0) {
        return false;
      } else {
        return true
      }
    }
    return false;
  };

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  }, [])

  return (
    <>
      <div className="service-detail-header">
        <Header />
      </div>
      <div className="servicedetail-wrapper">
        <Container maxWidth="lg">
          <div className="servicedetail-heading">
            <h1>{items?.name}</h1>
            {/* <ul>
              <li>
                <img src="/images/ratings-star.svg" />
                <span>4.2 ( 1K+ ratings )</span>
              </li>
              <li>
                <img src="/images/schedule.svg" />
                <span>Total 987 bookings</span>
              </li>
            </ul> */}
          </div>
          <div className="servicedetail-offers-slider">
            <OffersSlider banners={items?.banners} />
          </div>
          <div className="servicedetail-info">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                <div className="app-service-accordian-group">
                  {items?.items[0]?.items?.map((item, index) => (
                    <Accordion
                      expanded={expanded === index}
                      onChange={handleChange(index)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h3 className="app-service-accordian-title">
                          {item?.name} ({item?.items[0]?.items?.length})
                        </h3>
                      </AccordionSummary>
                      {item?.items[0]?.items?.map((item, index) => (
                        <AccordionDetails>
                          <div
                            className="app-service-accordian-content"
                            key={index}
                          >
                            <div className="app-service-accordian-content-card">
                              <div className="app-service-accordian-content-card-info">
                                <h3 className="app-service-accordian-content-card-title">
                                  {item?.name}
                                </h3>
                                <h2 className="app-service-accordian-content-card-price">
                                  <img src="/images/price-symbol.svg" />
                                  <span>{item?.rate}</span>
                                </h2>
                                {/* <ul className="app-service-accordian-content-card-rating">
                                  <li>
                                    <img src="/images/ratings-star.svg" />
                                    <span>4.2 ( 1K+ ratings )</span>
                                  </li>
                                  <li>
                                    <img src="/images/duration.svg" />
                                    <span>45 mins </span>
                                  </li>
                                </ul> */}
                                {/* <p className="app-service-accordian-content-card-para">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s... */}
                                {/* <span
                                    className="no-style-ion-button"
                                    id="open-modal"
                                    expand="block"
                                  >
                                    {" "}
                                    view more{" "}
                                  </span> */}
                                {/* </p> */}
                              </div>
                              <div className="app-service-accordian-content-card-image">
                                <img
                                  className="app-service-accordian-content-card-image-cnt"
                                  src={item?.icon}
                                />
                                <div className="app-service-add">
                                  {conditionForLocal(item?.service_id) ? (
                                    <div className="add-items-count-wrapper">
                                      <Button
                                        className="app-service-add-btn"
                                        onClick={() => {
                                          addItemToCart(
                                            item?.service_id,
                                            item?.rate,
                                            item?.name
                                          );
                                        }}
                                      >
                                        Add
                                      </Button>
                                    </div>
                                  ) : (
                                    <div className="add-items-count-wrapper">
                                      <Button
                                        className="add-items-trigger-inn"
                                        onClick={() => {
                                          if (localData?.token) {
                                            if (
                                              parseInt(
                                                getCartQuantityById(
                                                  roleContext?.cartList,
                                                  item?.service_id,
                                                  "service_id"
                                                )
                                              ) == 1
                                            ) {
                                              removeFromCart(
                                                getCartId(
                                                  roleContext?.cartList,
                                                  item?.service_id,
                                                  "service_id"
                                                )
                                              );
                                              return;
                                            }
                                            increaseOrDecrease(
                                              "decrease",
                                              getCartId(
                                                roleContext?.cartList,
                                                item?.service_id,
                                                "service_id"
                                              )
                                            );
                                          } else {
                                            if (parseInt(item.quantity) === 1) {
                                              removeFromCart(item.service_id);
                                              return;
                                            }
                                            increaseOrDecrease(
                                              "decrease",
                                              item.service_id,
                                              cartItems
                                            );
                                          }
                                        }}
                                      >
                                        <img src="/images/minus.svg" />
                                      </Button>
                                      <input
                                        className="add-items-show-input-alt"
                                        readOnly
                                        value={
                                          // itemQuantities
                                          localData?.token ?
                                            getCartQuantityById(
                                              roleContext?.cartList,
                                              item?.service_id,
                                              "service_id"
                                            )
                                            : getCartQuantityById(
                                              roleContext?.localCart,
                                              item?.service_id,
                                              "service_id"
                                            )
                                        }

                                      />

                                      <Button
                                        className="add-items-trigger-inn"
                                        onClick={() => {
                                          if (localData?.token) {
                                            increaseOrDecrease(
                                              "increase",
                                              getCartId(
                                                roleContext?.cartList,
                                                item?.service_id,
                                                "service_id"
                                              )
                                            );
                                          } else {
                                            increaseOrDecrease(
                                              "increase",
                                              item.service_id,
                                              cartItems
                                            );
                                          }
                                        }}
                                      >
                                        <img src="/images/plus.svg" />
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      ))}
                    </Accordion>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                <div className="service-promotions-wrapper">
                  <div
                    className="service-addto-cart"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "10px",
                    }}
                  >
                    <ul className="checkout-payment-summary-list">


                      {localData?.token ? (
                        roleContext.cartList.length > 0 ? (
                          roleContext.cartList.map((item, index) => (
                            <li key={index}>
                              <h6>{item.service_name}</h6>
                              <span>
                                {item.quantity}
                              </span>
                            </li>
                          ))
                        ) : (
                          <p>There are no items in the cart.</p>
                        )
                      ) : itemss.length > 0 ? (
                        itemss.map((item, index) => (
                          <li key={index}>

                            <h6>{item.service_name}</h6>
                            <span>
                              {item.quantity}
                            </span>

                          </li>
                        ))
                      ) : (
                        <p>There are no items in the cart.</p>
                      )}
                    </ul>
                  </div>
                  {/* <div className="service-addto-cart" 
                  // style={{position:'sticky', bottom: "20px", zIndex: "1"}}
                  >
                    <h6>
                      <img src="/images/price-symbol.svg" />
                      <span>{roleContext?.cartTotal}</span>
                    </h6>
                    <Link to="/checkout">
                      <Button variant="contained" color="primary">
                        View Cart
                      </Button>
                    </Link>
                  </div> */}
                </div>
                <div className="checkout-payment-sticky">
                  <div className="service-addto-cart">
                    {
                      localData?.token ? (
                        <h6>
                          <img src="/images/price-symbol.svg" alt="Price Symbol" />
                          <span>{(roleContext.cartTotal)}</span>
                        </h6>
                      ) : <h6>
                        <img src="/images/price-symbol.svg" alt="Price Symbol" />
                        <span>{overallRate}</span>
                      </h6>
                    }


                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => { navigate('/checkout') }}
                    >
                      View Cart
                    </Button>

                    {/* </Link>  */}
                    {/* <Link to="/checkout">
                      <Button variant="contained" color="primary">
                        View Cart
                      </Button>
                    </Link> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          {/* <div className="services-related-items">
            <div className="offers-heading">
              <h1>Related Services</h1>
              <p>Lorem Ipsum is simply dummy text of the printing</p>
            </div>
            <div className="app-special-product-service-slider">
              <SpecialServicesSlider />
            </div>
          </div> */}
        </Container>
      </div>
      <Footer />
    </>
  );
}
export default ServiceDetail;
