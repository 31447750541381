// import { Geolocation } from "@capacitor/geolocation";
import axios from "./axiosInstance";
export const findIndexValue = (list, id, key) => {
  return list?.findIndex((item) => item[`${key}`] == id);
};

export const getCartId = (list, id, key) => {
  return list[list?.findIndex((item) => item[`${key}`] == id)]?.id;
};

export const getCartQuantityById = (list, id, key) => {
  return list[list?.findIndex((item) => item[`${key}`] == id)]?.quantity;
};

export const getCurrentLocation = async () => {};

export const getOrderStatus = (id) => {
  const data = [
    {
      id:1,
      status:"Processing"
    },
    {
      id:2,
      status:"Work in Prrogress"
    },
    {
      id:3,
      status:"Completed"
    },
    {
      id:4,
      status:"Not Required"
    },
    {
      id:5,
      status:"Closed"
    },
    {
      id:127,
      status:"Enquiry"
    },
    {
      id:128,
      status:"Double Entry"
    },
    {
      id:130,
      status:"Long Distance"
    },
    {
      id:180,
      status:"Hold"
    },
    {
      id:194,
      status:"Complaint"
    },
    {
      id:300,
      status:"Pre Not Required"
    },
  ]

  const index = data.findIndex((item) => item.id == id)

  console.log(index)
  console.log(data[index].status)
  return data[index].status
}

// // import { Geolocation } from "@capacitor/geolocation";
// import axios from "./axiosInstance";
// // export const findIndexValue = (list, id, key) => {
// //   return list?.findIndex((item) => item[`${key}`] == id);
// // };

// export const findIndexValue = (list, id, key) => {
//   if (!Array.isArray(list)) {
//     return -1;
//   }
//   return list.findIndex((item) => item[`${key}`] == id);
// };

// export const getCartId = (list, id, key) => {
//   return list[list?.findIndex((item) => item[`${key}`] == id)]?.id;
// };


// export const getCartQuantityById = ( id, key) => {
//   let list =  JSON.parse(localStorage.getItem("cartItems")) || [];
//   if (!Array.isArray(list)) {
//     return 0;
//   }
//   return list[list?.findIndex((item) => item[`${key}`] == id)]?.quantity || 0;
// };


// export const getCurrentLocation = async () => {};