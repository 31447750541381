import * as React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import "../../styles/components/all-services.scss";

function AllServices({ services }) {
  return (
    <>
      <div className="services-tab-content-wrapper">
        <div className="services-tab-content-heading">
          <h1>Services</h1>
          {/* <p>Lorem Ipsum is simply dummy text of the printing</p> */}
        </div>
        <ul className="services-tab-content-list">
          {
            services && services.items[0]?.items.map(item => (
              <li className="services-tab-content-list-item">
                <Link
                  to="/service-detail"
                  state={{ items: item, main_service_id: item.main_service_id }}
                >
                  <Button>
                    <img src={item?.icon} />
                    <span className="services-tab-content-list-item-title">
                      {item?.name}
                    </span>
                  </Button>
                </Link>
              </li>
            ))
          }
        </ul>
      </div>
    </>
  );
}
export default AllServices;
