import * as React from "react";
import "../styles/pages/login.scss";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link, useNavigate, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import OTPInput from "otp-input-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { otpSchema } from "../utils/validations";
import axios from "../utils/axiosInstance";
import { setCookie } from "../utils/cookieHandling";
import { UserRoleContext } from "../utils/userAuthorization";
import { toast } from "react-toastify";

function Login() {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(otpSchema),
  });

  const location = useLocation();
  const history = useNavigate();
  const roleContext = React.useContext(UserRoleContext);

  const watchMobile = useWatch({
    control,
    name: "mobile",
  });

  // console.log(history)

  const onSubmit = async (formData) => {
    var bodyFormData = new FormData();
    bodyFormData.append("phone", location?.state?.mobile);
    bodyFormData.append("otp", formData?.mobile);
    const { data, status } = await axios.post(
      "appuserotpVerification",
      bodyFormData
    );
    if (data.code === 200) {
      var bodyFormCartData = new FormData();
      const items = JSON.parse(localStorage.getItem("cartItems")) || [];
      if (items?.length > 0) {
        bodyFormCartData.append("phone", data?.data?.phone);
        bodyFormCartData.append("token", data?.data?.token);
        bodyFormCartData.append("id", data?.data?.id);
        bodyFormCartData.append("cart_data", JSON.stringify(items));
        const { status: cartStatus } = await axios.post("addcartitems", bodyFormCartData);
        if (cartStatus == 200) {
          localStorage.removeItem("cartItems")
          localStorage.removeItem("overallRate")
          localStorage.removeItem("overallQuantity")
          roleContext.updateContext({ isLoggedIn: true });
          setCookie("token", data?.data);
          history("/checkout");
        }
      }else{
        roleContext.updateContext({ isLoggedIn: true });
        setCookie("token", data?.data);
        history("/checkout");
      }

    } else {
      toast.error("Invalid otp")
    }
  };
  // console.log(location.state);

  const onResend = async (formData) => {
    const mobile = localStorage.getItem("mobile");
    var bodyFormData = new FormData();
    bodyFormData.append("phone", mobile);
    const { data, status } = await axios.post("registeruser", bodyFormData);
    if (status === 200) {
      history("/otp-auth", { state: { mobile: mobile } });
      alert(data?.message);
    }
    // console.log(data)
  };


  return (
    <>
      <div className="app-login">
        <Container className="h-100" maxWidth="lg">
          <Grid className="h-100" container spacing={10}>
            <Grid className="h-100 hide-on-mobile" item md="6" lg="6" xl="6">
              <div className="app-login-image">
                {/* <img
                  className="app-login-page-logo"
                  src="/images/jigydi-logo.svg"
                /> */}
                <img
                  className="app-login-image-figure"
                  src="/images/splash-screen-web.svg"
                />
              </div>
            </Grid>
            <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
              <div className="app-login-header">
                <img
                  className="app-login-page-logo"
                  src="/images/jigydi-logo.svg"
                />
              </div>
              <div className="app-login-content">
                <div className="app-login-content-wrapper">
                  <div className="app-login-content-heading">
                    <h5>Authentication</h5>
                    <p>
                      Check your SMS messages. We’ve sent you the PIN at (+91)
                      {location?.state?.mobile}
                    </p>
                  </div>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="app-login-content-form"
                  >
                    <label>Enter OTP</label>
                    <div>
                      <div className="otp-input-fields">

                        <OTPInput
                          value={watchMobile}
                          onChange={(e) => {
                            setValue("mobile", e);
                          }}
                          autoFocus
                          OTPLength={4}
                          otpType="number"
                          inputStyle={{
                            width: "70px", // Adjust the width as per your requirements
                            height: "1000px", // Adjust the height as per your requirements
                            fontSize: "20px", // Adjust the font size as per your requirements
                          }}
                        />
                      </div>
                      <span style={{ color: "red" }}>
                        {errors?.mobile?.message}
                      </span>
                    </div>

                    <Button
                      type="submit"
                      className="verify-button"
                      variant="contained"
                      color="primary"
                    >
                      Verify
                    </Button>
                  </form>
                  <p className="app-login-description">
                    Not yet received?
                    <Link>
                      <Button color="primary" variant="text" onClick={onResend}>
                        Resend OTP
                      </Button>
                    </Link>
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
export default Login;