import React, {useEffect } from "react";
import ReactGA from 'react-ga';
import { UserRoleContext } from "./utils/userAuthorization.js";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home.js";
import ServiceDetail from "./pages/ServiceDetail.js";
import "./App.css";
import "./styles/base/main.scss";
import Login from "./pages/Login";
import Register from "./pages/Register";
import OtpAuth from "./pages/OtpAuth";
import Checkout from "./pages/Checkout";
import { getCookie } from "./utils/cookieHandling.js";
import AddAddress from "./pages/AddAddress.js";
import Profile from "./pages/Profile.js";
import OrderHistory from "./pages/OrderHistory.js";
import HistoryDetail from "./pages/HistoryDetail.js";
import AddressList from "./pages/AddressList.js";
import Landing from "./pages/Landing.js";
import Privacy_Policy from "./pages/Privacy_Policy.js";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const TRACKING_ID = "AW-11323554853";
ReactGA.initialize(TRACKING_ID);
const TRACKING_ID2 = "G-HXRGY81SJD";
ReactGA.initialize(TRACKING_ID2);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const roleContext = React.useContext(UserRoleContext);

  const localData = getCookie("token");

  const notLoggedIn = (
    <>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/otp-auth" element={<OtpAuth />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </>
  );

  const loggedIn = (
    <>
      <Route exact path="/home" element={<Home />} />
      <Route exact path="/service-detail" element={<ServiceDetail />} />
      <Route exact path="/checkout" element={<Checkout />} />
      {/* <Route exact path="/" element={<Home />} /> */}
    </>
  );

  return (
    <div className="App">
      <>
        <Routes>
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/user_profile" element={<Profile />} />
          <Route exact path="/service-detail" element={<ServiceDetail />} />
          <Route exact path="/checkout" element={<Checkout />} />
          <Route exact path="/address" element={<AddAddress />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/otp-auth" element={<OtpAuth />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/history" element={<OrderHistory />} />
          <Route exact path="/history-detail" element={<HistoryDetail />} />
          <Route exact path="/address-list" element={<AddressList />} />
          <Route exact path="/add-address" element={<AddAddress />} />
          <Route exact path="/services/paintingandwaterproofing" element={<Landing />} />
          <Route exact path="/privacy-policy" element={<Privacy_Policy />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </>
      <ToastContainer limit={1} autoClose={2000} />
    </div>
  );
}

export default App;
