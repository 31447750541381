import * as yup from "yup";

export const loginSchema = yup.object({
  mobile: yup.string().required("It is a required field").min(10).max(10),
});

export const registerSchema = yup.object({
  phone: yup.string().required("It is a required field").min(10).max(10),
  firstName: yup.string().required("It is a required field"),
  lastName: yup.string().required("It is a required field"),
  email: yup.string().email().required("It is a required field"),
});
export const otpSchema = yup.object({
  mobile: yup.string().required("It is a required field"),
});

export const addAddressSchema = yup
.object({
  address: yup.string().required("It is a required field"),
  pincode: yup.string().required("It is a required field"),
  city: yup.string().required("It is a required field"),
  state: yup.string().required("It is a required field"),
})
