import { Link, useNavigate } from "react-router-dom";
import { deleteAll, getCookie } from "../utils/cookieHandling";
import { useContext, useEffect, useState } from "react";
import { UserRoleContext } from "../utils/userAuthorization";
import axios from "../utils/axiosInstance";
import { Grid, Box, Button } from '@mui/material';
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

export default function AddressList() {
  const history = useNavigate();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addresses, setaddresses] = useState([]);
  const roleContext = useContext(UserRoleContext);
  const getAllCategories = async () => {
    var bodyFormData = new FormData();
    const localData = getCookie("token");
    bodyFormData.append("phone", localData?.phone);
    bodyFormData.append("token", localData?.token);
    bodyFormData.append("id", localData?.id);
    const res = await axios.post("getaddress", bodyFormData);
    // console.log(data?.data)
    if (res?.status === 200) {
      let address = JSON.parse(localStorage.getItem('addresses'))
      // console.log(data, address)
      let localAddresses = address?.length > 0 ? [...address] : []
      let addressArr = [...res?.data?.data, ...localAddresses]
      setaddresses(addressArr)
    }
  };

  // useEffect(() => {
  //   let address = JSON.parse(localStorage.getItem('addresses'))
  //   setaddresses([...addresses, ...address])
  // }, [])

  // console.log(addresses)

  useEffect(() => {
    roleContext.getCartDetails();
    getAllCategories();
  }, []);

  const handleAddress = (item) => {
    setSelectedAddress(item);
    // history('/add-address', { state: item });
  };

  const handleEditAddress = (item) => {
    history('/add-address', { state: item });
  };

  // console.log('addd',addresses)

  return (
    <>

      <Header />
      <Grid container spacing={4} className="manage-address-app-list" justifyContent="center">
        <Grid item xs={12} sm={10} md={8}>
          <div className="app-back-wrapper">
            <h2 className="app-back-title">Manage Addresses</h2>
          </div>

          {addresses.map((item) => (
            <Box key={item.id} mb={4} sx={{
              backgroundColor: item === selectedAddress ? 'lightblue' : 'white',
            }}
              onClick={() => handleAddress(item)}>
              <Box className="app-history-detail-booking-card" display="flex" justifyContent="space-between">
                <ul className="app-history-detail-booking-list">
                  <li className="app-history-detail-booking-list-item p-0">
                    <div className="app-history-detail-booking-list-info">
                      <h2>{item.type === "1" ? 'Home' : item.type === "2" ? 'Office' : "others"}</h2>
                      <p>{`${item.address}, ${item.city}, ${item.state}, ${item.pincode}`}</p>
                    </div>
                  </li>
                </ul>
                <div className="mt-20 d-flex">
                  <Button
                    // component={Link}
                    // to={{ pathname: '/add-address', state: item }}
                    onClick={() => handleEditAddress(item)}
                    className="ion-activatable ripple-parent"
                    variant="contained"
                    color="primary"
                    sx={{ height: 30 }}
                  >
                    {/* {console.log("state item", item.type)} */}
                    Edit
                  </Button>
                </div>
              </Box>
            </Box>
          ))}

          <div className="app-profile-card-cnt" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <Link to="/add-address">
              <Button variant="contained" color="primary">
                {/* <img src="/images/my-bookings.svg" alt="My Bookings" /> */}
                Add Address
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}
